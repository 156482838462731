import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toogleMenu } from "../../redux/features/modals/modalsSlice";
import { HiOutlineMenu } from "react-icons/hi";
import {
  BsBuildingGear,
  BsFileEarmarkMedical,
  BsFillFileEarmarkSpreadsheetFill,
  BsFolder2,
  BsGlobe,
  BsTable,
} from "react-icons/bs";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { setNewTab } from "../../redux/features/ui/uiSlice";
import CustomTooltip from "./side bar/CustomToolTip";
import { useNavigate } from "react-router-dom";

const SideBar = ({
  currentMenus,
  setCurrentMenus,
  previousStack,
  setPreviousStack,
  currrentTitle,
  setCurrentTitle,
  titleStack,
  setTitleStack,
}) => {
  const [sideBarWidth, setSideBarWidth] = React.useState(320);

  const menuOpen = useSelector((state) => state.modals.menu);
  const screens = useSelector((state) => state.screens.screens);
  const dispatch = useDispatch();
  const sideBarRef = React.useRef();
  const handleRef = React.useRef();
  const navigate = useNavigate();

  const settings = useSelector((state) => state.settings);

  const openMenuFolder = (data, item) => {
    previousStack.push(data);
    titleStack.push(currrentTitle);
    setPreviousStack(previousStack);
    setTitleStack(titleStack);
    setCurrentMenus(item.children);
    setCurrentTitle(item.text);
  };

  React.useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.key === "b") {
        dispatch(toogleMenu());
      }
    });
  }, []);

  const MenuItem = ({ item, index }) => (
    <div
      id={`id-mn${index}`}
      onClick={() => {
        if (settings.app.doubleClickOnMenu != 1) {
          navigate("/");
          dispatch(setNewTab({ ...screens[item.idx], id: Date.now() }));
        }
      }}
      onDoubleClick={() => {
        if (settings.app.doubleClickOnMenu == 1) {
          navigate("/");
          dispatch(setNewTab({ ...screens[item.idx], id: Date.now() }));
        }
      }}
      className="s1-web-menu-item"
    >
      <div className="d-flex">
        {
          {
            EditMaster: <BsFileEarmarkMedical color="var(--bs-orange)" />,
            Report: <BsFillFileEarmarkSpreadsheetFill color="var(--bs-teal)" />,
            Dialog: <BsBuildingGear color="var(--bs-danger)" />,
            WebPage: <BsGlobe color="rgb(64, 180, 252)" />,
            Ergani: <MdOutlineQrCodeScanner color="var(--bs-gray-600)" />,
            EditList: <BsTable color="var(--bs-yellow)" />,
          }[item.cmd]
        }
      </div>
      <span className="text-truncate me-2">{item?.text}</span>
    </div>
  );

  const FolderItem = ({ index, data, item }) => (
    <div
      id={`id-mn${index}`}
      onClick={() => settings.app.doubleClickOnMenu != 1 && openMenuFolder(data, item)}
      onDoubleClick={() => settings.app.doubleClickOnMenu == 1 && openMenuFolder(data, item)}
      className="s1-web-menu-item"
    >
      <div className="d-flex">
        <BsFolder2 color="var(--bs-gray-500)" />
      </div>
      <span className="text-truncate me-2">{item?.text}</span>
    </div>
  );

  const renderMenuItems = (data) => {
    return data.map((item, index) =>
      item?.children && item?.children.length ? (
        !menuOpen ? (
          <CustomTooltip key={index} title={item?.text}>
            <FolderItem data={data} index={index} item={item} />
          </CustomTooltip>
        ) : (
          <FolderItem key={index} data={data} index={index} item={item} />
        )
      ) : !menuOpen ? (
        <CustomTooltip key={index} title={item?.text}>
          <MenuItem index={index} item={item} />
        </CustomTooltip>
      ) : (
        <MenuItem key={index} index={index} item={item} />
      )
    );
  };

  const resize = (e) => {
    let width = e.clientX - sideBarRef.current.offsetLeft;
    if (width < 51) width = 53;
    setSideBarWidth(width);
  };

  const stopResize = (e) => {
    window.removeEventListener("mousemove", resize);
    window.removeEventListener("mouseup", stopResize);
  };

  const initResize = () => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResize);
  };

  return (
    <aside className="sideBar" ref={sideBarRef} style={{ width: menuOpen ? `${sideBarWidth}px` : "53px" }}>
      {menuOpen ? (
        <div className="sideBar-handle" ref={handleRef} onMouseDown={initResize} />
      ) : (
        <div className="sideBar-handle-closed" />
      )}
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <HiOutlineMenu
          size={20}
          className="mx-3 my-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(toogleMenu());
          }}
        />
        <hr className="m-0" />
        <div className="s1-web-menu">
          {previousStack.length ? (
            <div className="s1-web-menu-item current-folder text-light">
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const prevState = previousStack.pop();
                  setPreviousStack(previousStack);
                  setCurrentMenus(prevState);
                  const prevTitle = titleStack.pop();
                  setTitleStack(titleStack);
                  setCurrentTitle(prevTitle);
                }}
              >
                <IoIosArrowBack size="1rem" />
              </div>

              <div className="vr my-2 me-2"></div>
              <span className="mx-2 text-truncate">{currrentTitle}</span>
            </div>
          ) : null}
          {currentMenus ? (
            renderMenuItems(currentMenus)
          ) : (
            <div className="d-flex justify-content-center align-items-center">No data</div>
          )}
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
