import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import { AiOutlineMail } from "react-icons/ai";

const S1Email = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");

  const boundRef = React.useRef(null);

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    screenState == "form" && !grid && setValue(data?.[table]?.[0]?.[key] ?? "");
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    grid && setValue(currentGridLine?.line?.[key] ?? "");
  }, [currentGridLine?.line?.[key]]);

  const handleChange = async (value) => {
    setIsFocused(false);
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if ((data?.[table]?.[0]?.[key] ?? "") != value) {
        if (objectState == "view") {
          await dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ value: "edit", tabID }));
        }
        handleCalculate(value);
      }
    }
  };

  const handleClear = async () => {
    setValue("");
    setIsFocused(false);

    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate("");
    }
  };

  const openLink = () => {
    if (value != "") window.location.href = "mailto:" + value;
  };

  return (
    <div
      className="s1email w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && commandParams.readonly)}
              value={value}
              autoComplete="off"
              name={element.name}
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={(e) => handleChange(e.target.value)}
            />
            {value != "" &&
              (clear || isFocused) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
            <div role={value == "" ? "default" : "button"} onClick={value == "" ? undefined : openLink}>
              <AiOutlineMail className={value == "" ? "disabled" : ""} color="rgb(64, 180, 252)" size="20px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S1Email;
