import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setLoading,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import {
  getAccesRights,
  getCommandParams,
  getDialog,
  getFilters,
  getFormDesign,
  getObjectState,
  getRecData,
} from "../../../redux/selectors";
import getSelectorData from "../../../services/getSelectorData";
import PickerSpinner from "../PickerSpinner";
import getSelectorFields from "../../../services/getSelectorFields";
import { setError } from "../../../redux/features/modals/modalsSlice";
import { calculate } from "../../../services/calculate";
import cleanEditor from "../../functions/cleanEditor";

const S1MemoryTable = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const dialog = useSelector((state) => getDialog(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const objectState = useSelector((state) => getObjectState(state, tabID));

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [editorString, setEditorString] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model =
    screenState == "form"
      ? formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0]
      : dialog && dialog.model[table].fields.filter((el) => el.name == key)[0];

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);
  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleChange = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    const setTheValue = async () => {
      dispatch(setLoading({ show: true, tabID }));
      const editor = cleanEditor(tabID, model, element, grid);
      setEditorString(editor);
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      if (GetSelectorData.success) {
        if (element.selectorVF && element.selectorVF != "") {
          const selectorObj = {};
          GetSelectorData.model.map((item, index) => {
            selectorObj[item.name] = GetSelectorData.data.find((x) => x?.[0] == element.value.split("|")[0])[index];
          });
          const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);

          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: `${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`,
            })
          );
        }
      }
      dispatch(setLoading({ show: false, tabID }));
    };

    // if (screenState == "dialog" && element.value && element.value != "") {
    //   if (element.selectorVF && element.selectorVF != "") {
    //     setTheValue();
    //   } else {
    //     setValue(element.value);
    //   }
    // }
  }, []);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      const values = (data?.[table]?.[0]?.[key] ?? "").split("|");
      setValue(values[values.length - 1]);
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      const values = (filters?.[table]?.[key] ?? "").split("|");
      setValue(values[values.length - 1] && values[values.length - 1] != "" ? values[values.length - 1] : values[0]);
    }
  }, [filters?.[table]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid) {
      const values = (currentGridLine?.line?.[key] ?? "").split("|");
      setValue(values[values.length - 1]);
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    setSearchText("");
    if (openPicker) {
      searchRef.current.focus();
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (openPicker) {
      setOpenPicker(false);
      setIsFocused(false);
    } else {
      setSearchText("");
      setPickerLoading(true);
      setOpenPicker(true);
      setIsFocused(true);
      const editor = cleanEditor(tabID, model, element, grid);
      setEditorString(editor);
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      setPickerLoading(false);
    }
  };

  const handleClear = async () => {
    setSelectedRow([]);
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        !grid && (await dispatch(changeToBuffer({ tabID })));
        !grid && dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleChange("");
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      var filter, td, i, txtValue;
      var is = [];
      filter = value.toUpperCase();
      td = tableRef.current.querySelectorAll(".searchable");
      for (i = 0; i < td.length; i++) {
        td[i].parentElement.style.display = "none";
        txtValue = td[i].textContent || td[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          is.push(td[i].parentElement);
        } else {
          td[i].parentElement.style.display = "none";
        }
        is.forEach((t) => (t.style.display = ""));
      }
    }
  };

  const handleClickOnRow = async (row) => {
    setSelectedRow(row);
    setOpenPicker(false);
    setIsFocused(false);
    if (element.selectorVF && element.selectorVF != "") {
      console.log(row);
      const selectorObj = {};
      selectorData.model.map((item, index) => {
        selectorObj[item.name] = row[index];
      });
      const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);
      if (screenState == "dialog") {
        dispatch(
          setFilter({
            tabID,
            table,
            key: element.name.split(".")[1],
            value: `${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`,
          })
        );
      } else if (screenState == "form") {
        if (objectState == "view") {
          !grid && (await dispatch(changeToBuffer({ tabID })));
          !grid && dispatch(setObjectState({ value: "edit", tabID }));
        }
        await handleChange(`${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`);
      }
    } else {
      dispatch(setLoading({ show: true, tabID }));
      const filters = editorString.split("|")[4];
      const tablename = editorString.split("|")[1];
      const keyname = editorString.split("|")[2];
      const keyvalue = row[0];
      const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);
      var key = resultfields[0];
      var value = resultfields[resultfields.length - 1];
      const selectorFields = await getSelectorFields({
        filters,
        tablename,
        keyvalue,
        keyname,
        resultfields: resultfields.join(","),
      });
      if (selectorFields.success) {
        if (screenState == "dialog") {
          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: `${selectorFields.rows[0][key]}|${selectorFields.rows[0][value]}`,
            })
          );
        } else if (screenState == "form") {
          if (objectState == "view") {
            !grid && (await dispatch(changeToBuffer({ tabID })));
            !grid && dispatch(setObjectState({ value: "edit", tabID }));
          }
          await handleChange(`${selectorFields.rows[0][key]}|${selectorFields.rows[0][value]}`);
        }
      } else {
        dispatch(setError({ show: true, message: selectorFields.error }));
      }
      dispatch(setLoading({ show: false, tabID }));
    }
  };

  return (
    <div
      className="s1memorytable w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption && element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1 }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              role="button"
              className={`w-100${
                element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                  ? " disabled"
                  : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              readOnly={true}
              value={value}
              name={element.name}
              onClick={
                !(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update))
                  ? handlePicker
                  : undefined
              }
            />
            {value != "" &&
              (clear || openPicker) &&
              !(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={
              !(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update))
                ? handlePicker
                : undefined
            }
          >
            <BsChevronDown
              className={`${
                element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                  ? " disabled"
                  : ""
              }`}
              color="rgb(64, 180, 252)"
              size="20px"
            />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div"
          ref={pickerRef}
          style={{
            left:
              boundRef.current.getBoundingClientRect().left + 400 > window.innerWidth
                ? boundRef.current.getBoundingClientRect().right - 400
                : boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 300 > window.innerHeight
                ? 300 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "300px",
            overflow: "hidden",
          }}
        >
          {pickerLoading && <PickerSpinner />}
          <div className="bg-light">
            <div className="inputGroup m-0 gap-1" style={{ borderBottom: "1px solid black" }}>
              <div className="ms-1 d-flex justify-content-center align-items-center">
                <BsSearch color="rgb(64, 180, 252)" />
              </div>
              <input
                ref={searchRef}
                style={{ height: "30px" }}
                placeholder="Αναζήτηση"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {searchText != "" && (
                <div
                  onClick={() => {
                    handleSearch("");
                  }}
                >
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
            </div>
          </div>
          <div className="input-picker bg-light w-100 h-100" style={{ overflow: "auto" }}>
            {selectorData?.success ? (
              <table className="input-picker-table w-100" ref={tableRef}>
                <tbody>
                  {selectorData.data.map((el, index) => {
                    return (
                      <tr
                        key={index}
                        className={`input-picker-table-row${
                          selectedRow?.[0] == el?.[0] ? " input-picker-table-row-selected" : ""
                        }`}
                        onClick={() => handleClickOnRow(el)}
                      >
                        {el.map((item, index) => {
                          return (
                            <td className="p-1 searchable" key={index} style={{ height: "40px" }}>
                              {item}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{selectorData.error}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default S1MemoryTable;
