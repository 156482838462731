import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBuffData, getFormDesign, getNewData, getObjectState } from "../../../../../redux/selectors";
import { setLoading, setGetData, setObjectState } from "../../../../../redux/features/ui/uiSlice";
import { setError, setMessage } from "../../../../../redux/features/modals/modalsSlice";
import setData from "../../../../../services/setData";
import { BiQuestionMark } from "react-icons/bi";

const ModalSaveRecord = ({ show, setShow, tabID, functionNo }) => {
  const dispatch = useDispatch();
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const newData = useSelector((state) => getNewData(state, tabID));
  const buffData = useSelector((state) => getBuffData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const handleAccept = async () => {
    setShow(false);
    dispatch(setLoading({ show: true, tabID }));
    const key = formDesign.key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
    var locateinfo = "";
    Object.entries(formDesign.model).forEach(([key, value], index) => {
      locateinfo += index == 0 ? `${key}:` : `;${key}:`;
      if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
      else
        value.fields.map((item) => {
          locateinfo += `${item.name},`;
        });
    });
    const data = await setData({
      key: objectState == "new" ? "" : keyValue ?? "",
      data: newData,
      locateinfo,
      tabID,
    });

    if (data.success) {
      dispatch(setGetData({ value: data, tabID }));
      dispatch(setObjectState({ value: "view", tabID }));
      if (data.message) {
        dispatch(setMessage({ show: true, message: data.message }));
      }
      dispatch(setLoading({ show: false, tabID }));
      functionNo();
    } else {
      console.log(data);
      dispatch(setLoading({ show: false, tabID }));
      dispatch(setError({ show: true, message: data.error }));
    }
  };
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      onHide={() => setShow(false)}
      size="sm"
      centered
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-color-opposite)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
      <Modal.Body className="d-flex flex-column" style={{ userSelect: "none" }}>
        Να καταχωρηθεί η εγγραφή;
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex justify-content-end align-items-center">
        <Button onClick={() => handleAccept()}>Ναι</Button>
        <Button onClick={() => functionNo()}>Όχι</Button>
        <Button onClick={() => setShow(false)}>Άκυρο</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSaveRecord;
