import { setGetData, setObjectEvent, setObjectState } from "../../redux/features/ui/uiSlice";
import setData from "../../services/setData";
import store from "../../redux/store";
import { getBuffData, getFormDesign, getObjectState } from "../../redux/selectors";
import { setError, setMessage } from "../../redux/features/modals/modalsSlice";

export const afterCalculateDinner = async (tabID, sn, data, calcData) => {
  if (sn == "01104290081621") {
    if (data?.SOEXPEN?.[0]?.CCCDINNERCALC == 1 && calcData?.SOEXPEN?.[0]?.CCCDINNERCALC == 0) {
      //   store.dispatch(setDinnerLines({ tabID, data: calcData?.ACTLINES ?? [] }));
      const state = store.getState();
      const formDesign = getFormDesign(state, tabID);
      const buffData = getBuffData(state, tabID);
      const objectState = getObjectState(state, tabID);
      const key = formDesign.key;
      const tableName = key.split(".")[0];
      const fieldName = key.split(".")[1];
      const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
      var locateinfo = "";
      Object.entries(formDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const res = await setData({
        key: objectState == "new" ? "" : keyValue ?? "",
        data,
        locateinfo,
        tabID,
      });

      if (res.success) {
        await store.dispatch(setObjectEvent({ tabID, value: "AFTER_POST" }));
        store.dispatch(setGetData({ value: res, tabID }));
        store.dispatch(setObjectState({ value: "view", tabID }));
        if (res.message) {
          store.dispatch(setMessage({ show: true, message: res.message }));
        }
      } else {
        console.log(data);
        store.dispatch(setError({ show: true, message: res.error }));
      }
    }
  }
};
