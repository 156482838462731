import { createSlice } from "@reduxjs/toolkit";
import { setSettingsModal } from "../modals/modalsSlice";

const local = JSON.parse(localStorage.getItem("settings")) ?? {};
const initialState = {
  app: local?.app ?? {
    menuOpen: false,
    doubleClickOnMenu: false,
    singleClickOnRecord: false,
    confirmExit: false,
    clickToSort: true,
    gridClickToSort: true,
    browserLimit: 10000,
    browserPageSize: 30,
    gridVisibleLines: 5,
    browserSearchActive: false,
    askForDeleteInGrid: false,
    popUpRedirection: false,
    calendarFirstDay: 1,
    calendarBussinesDays: "1,2,3,4,5",
    calendarSlots: "00:30:00",
    calendarStart: "09:00",
    calendarEnd: "17:00",
    calendarColor: "#0d6efd",
    calendarColorZoneField: "",
    calendarColorZones: [],
    enablePortal: 0,
    desktopNotificationPosition: "top-right",
    notificationDuration: 10000,
  },
  cloudStorage: local?.cloudStorage ?? {},
  printerOptions: local?.printerOptions ?? {},
  draft: {},
  hasSettingsInDB: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    set: (state, action) => {
      state.draft[action.payload.key] = action.payload.value;
    },
    setSettings: (state, action) => {
      state.app.calendarColorZoneField = "";
      state.app = { ...state.app, ...action.payload };
      localStorage.setItem("settings", JSON.stringify(state));
    },
    hasSettingsInDB: (state) => {
      state.hasSettingsInDB = true;
    },
    setPrinterOptions: (state, action) => {
      state.printerOptions = { ...state.printerOptions, [action.payload.key]: action.payload.value };
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setDropBox: (state, action) => {
      state.cloudStorage.dropbox = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setGoogle: (state, action) => {
      state.cloudStorage.google = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
  },
  extraReducers: (builder) =>
    builder.addCase(setSettingsModal, (state, action) => {
      if (!action.payload) {
        state.draft = {};
      }
    }),
});

export const { set, setSettings, hasSettingsInDB, setPrinterOptions, setDropBox, setGoogle } = settingsSlice.actions;

export default settingsSlice.reducer;
