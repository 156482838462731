import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminPanelRoute = () => {
  const admin = useSelector((state) => state.admin);
  const navigate = useNavigate();

  React.useEffect(() => {
    admin === null && navigate(`/admin-panel`);
  }, [admin]);

  return admin !== null && <Outlet />;
};

export default AdminPanelRoute;
