import React from "react";
import { SiGoogledrive } from "react-icons/si";
import useDrivePicker from "react-google-drive-picker";
import { useDispatch } from "react-redux";
import {
  addMultipleLinesToGrid,
  changeToBuffer,
  setObjectState,
  triggerGridChange,
} from "../../../../redux/features/ui/uiSlice";
import { useSelector } from "react-redux";
import { getNewData, getObjectState } from "../../../../redux/selectors";

const GoogleDrive = ({ tabID, element, setMenu }) => {
  const dispatch = useDispatch();
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const [openPicker] = useDrivePicker({
    authImmediate: true,
  });

  const handleAddNewDocs = async (data) => {
    if (data.action === "picked") {
      const lines = [];
      for (const docs of data.docs) {
        const { url, name } = docs;
        lines.push({
          SOFNAME: url,
          NAME: name,
        });
      }
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      const gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(addMultipleLinesToGrid({ tabID, value: lines, name: element.model }));
    }
  };

  const handleOpenPicker = (e) => {
    e.stopPropagation();
    setMenu({ show: false });
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ["https://www.googleapis.com/auth/drive"],
      callbackFunction: (data) => handleAddNewDocs(data),
    });
  };
  return (
    <div className="tab-list-context-menu-action" role="button" onClick={handleOpenPicker}>
      <div className="d-flex align-items-center">
        <SiGoogledrive className="me-1" />
        <span>Google Drive</span>
      </div>
    </div>
  );
};

export default GoogleDrive;
