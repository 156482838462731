import React from "react";
import TopBarOnWebPage from "../../bars/top bars/web page/TopBarOnWebPage";
import { useSelector } from "react-redux";
import { getCommandByIndex } from "../../../redux/selectors";

const WebPage = ({ tabID }) => {
  const command = useSelector((state) => getCommandByIndex(state, tabID));
  const frameRef = React.useRef();
  const login = useSelector((state) => state.login);
  const session = useSelector((state) => state.session);
  const systemParams = useSelector((state) => state.systemParams);

  const paramsObj = { login, session, systemParams };

  const replacePlaceholders = (str, values) => {
    return str.replace(/\${{(.*?)}}/g, (_, match) => {
      const keys = match.split(".");
      let nestedValue = values;

      for (const key of keys) {
        if (nestedValue && nestedValue.hasOwnProperty(key)) {
          nestedValue = nestedValue[key];
        } else {
          return match; // Placeholder not found, return original string
        }
      }

      return nestedValue;
    });
  };

  return (
    <>
      <TopBarOnWebPage frameRef={frameRef} />
      <iframe
        ref={frameRef}
        title="preview"
        style={{ height: "100%", width: "100%" }}
        src={replacePlaceholders(command, paramsObj)}
      />
    </>
  );
};

export default WebPage;
