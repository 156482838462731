import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setAbout } from "../../../redux/features/modals/modalsSlice";

const ModalAbout = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const session = useSelector((state) => state.session);
  const systemParams = useSelector((state) => state.systemParams);
  const about = useSelector((state) => state.modals.about);

  const render = [
    { name: "Username:", value: login?.username },
    { name: "Όνομα:", value: session?.refIDName },
    { name: "Επωνυμία:", value: systemParams?.companyinfo?.name },
    { name: "Υπ/μά:", value: systemParams?.companyinfo?.branch?.name },
    { name: "Διεύθυνση:", value: systemParams?.companyinfo?.address },
    { name: "ΑΦΜ:", value: systemParams?.companyinfo?.afm },
    { name: "ΔΟΥ:", value: systemParams?.companyinfo?.irsdata },
    { name: "SΝ:", value: systemParams?.serialnumber },
    { name: "S1 version", value: systemParams?.s1version },
    { name: "Web CRM version:", value: process.env.REACT_APP_VERSION },
    { name: "Web CRM χρήστες:", value: session?.users },
    { name: "Λήξη:", value: dateformat(session?.blcdate) },
  ];

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={about}
      animation={false}
      size="md"
      onHide={() => dispatch(setAbout(false))}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Info
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {render.map((item, index) => (
          <div className="d-flex w-100 mb-3" key={index}>
            <div
              style={{
                width: "180px",
                borderBottom: "1px dotted #b1b1b1",
                fontSize: "1.1rem",
                color: "var(--bs-gray)",
                userSelect: "none",
              }}
            >
              {item.name}
            </div>
            <div className="text-truncate" style={{ borderBottom: "1px solid #b1b1b1", fontSize: "1rem", flex: 1 }}>
              {item.value}
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default ModalAbout;

const dateformat = (date) => {
  const d = new Date(date);
  return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
};
