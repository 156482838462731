import React from "react";
import { Button, Modal } from "react-bootstrap";
import { BiQuestionMark } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { changeToBuffer, setChange, setLoading, setObjectState } from "../redux/features/ui/uiSlice";
import grVatInfo from "../services/grVatInfo";
import { setError } from "../redux/features/modals/modalsSlice";
import CrmInput from "../application/ui/CrmInput";
import { useSelector } from "react-redux";
import { getObjectState } from "../redux/selectors";

export const afmCheckFields = ["CUSTOMER.AFM"];

const afmObjectFields = {
  AFM: "ΑΦΜ",
  Onomasia: "Επωνυμία",
  CommerTitle: "Τίτλος",
  DoyDescr: "ΔΟΥ",
  FirmActDescr: "Επάγγελμα",
  PostalAddress: "Διεύθυνση",
  PostalAddressNo: "Αριθμός",
  PostalAreaDescription: "Πόλη",
  PostalZipCode: "ΤΚ",
};

const afmS1Fields = {
  AFM: "AFM",
  Onomasia: "NAME",
  CommerTitle: "SOTITLE",
  DoyDescr: "IRSDATA",
  FirmActDescr: "JOBTYPETRD",
  PostalAddress: "ADDRESS",
  PostalAddressNo: "0",
  PostalAreaDescription: "CITY",
  PostalZipCode: "ZIP",
};

const ModalAfmCheck = ({ show, setShow, afm, tabID, object }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const [confirm, setConfirm] = React.useState(false);
  const [data, setData] = React.useState({});

  const getVat = async () => {
    setShow(false);
    dispatch(setLoading({ tabID, show: true }));
    const response = await grVatInfo(afm);
    dispatch(setLoading({ tabID, show: false }));
    if (response.success) {
      if (response.data.ErrorCode == null) {
        setData(response.data);
        setConfirm(true);
      } else {
        dispatch(setError({ show: true, message: response.data.ErrorDescr }));
      }
    } else {
      dispatch(setError({ show: true, message: response.error }));
    }
  };

  const setValues = async () => {
    if (objectState == "view") {
      await dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ value: "edit", tabID }));
    }

    setConfirm(false);
    Object.keys(data).map((key) => {
      if (afmObjectFields.hasOwnProperty(key) && key != "PostalAddressNo" && key != "PostalAddress") {
        dispatch(
          setChange({
            tabID,
            table: object,
            key: afmS1Fields[key],
            value: data[key] != null ? data[key] : "",
          })
        );
      }
    });
    if (data.PostalAddress != null) {
      var address;
      if (data.PostalAddressNo != "" && data.PostalAddressNo != "0") {
        address = data.PostalAddress + " " + data.PostalAddressNo;
      } else {
        address = data.PostalAddress;
      }
      dispatch(setChange({ tabID, table: object, key: "ADDRESS", value: address }));
    }
    setData({});
  };

  return (
    <>
      <Modal
        // backdropClassName="above-all-backdrop print"
        // dialogClassName="above-all-dialog"
        show={show}
        animation={true}
        onHide={() => setShow(false)}
        centered
        size="sm"
      >
        <span
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "var(--bs-warning)",
            color: "var(--bs-dark)",
            borderRadius: "50%",
          }}
          className="d-flex justify-content-center align-items-center shadow"
        >
          <BiQuestionMark size="3rem" className="m-1 p-1" />
        </span>

        <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
        <Modal.Body className="d-flex justify-content-start align-items-center m-0" style={{ userSelect: "none" }}>
          Λήψη ΑΦΜ απο ΓΓΠΣ;
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
          <Button onClick={getVat}>Ναι</Button>
          <Button onClick={() => setShow(false)}>Όχι</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        // backdropClassName="above-all-backdrop print"
        // dialogClassName="above-all-dialog"
        show={confirm}
        animation={true}
        onHide={() => setConfirm(false)}
        // className="d-flex align-items-end"
        centered
        size="lg"
        // dialogClassName="p-3"
        // contentClassName="rounded overflow-hidden shadow"
      >
        <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
        <Modal.Body
          className="d-flex justify-content-start align-items-center m-0 pt-0 flex-column"
          style={{ userSelect: "none" }}
        >
          {Object.keys(afmObjectFields).map((key, index) => {
            return (
              <CrmInput
                key={key}
                readOnly={true}
                label={afmObjectFields[key]}
                value={data[key] != null ? data[key] : ""}
                index={index}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
          <Button onClick={setValues}>Ενημέρωση</Button>
          <Button onClick={() => setConfirm(false)}>Ακύρωση</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAfmCheck;
