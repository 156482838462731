import React from "react";
import PowererBy from "../../../assets/PowererBy";
import { Alert, Button, Form } from "react-bootstrap";
import CrmInput from "../../ui/CrmInput";
import login from "../../../services/login";
import Spinner from "../../ui/Spinner";
import checkAdminRights from "../../../services/checkAdminRights";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAdmin } from "../../../redux/features/admin/adminSlice";

const AdminPanel = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [connection, setConnection] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [adminPassword, setAdminpassword] = React.useState("");

  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [wrongConnection, setWrongConnection] = React.useState(false);
  const [wrongPassUser, setWrongPassUser] = React.useState(false);
  const [wrongAdminpassword, setWrongAdminPassword] = React.useState(false);

  const inputs = [
    {
      controlId: "server",
      label: "Server",
      type: "text",
      value: connection,
      inputMode: "",
      condition: wrongConnection,
      autoComplete: "off",
      function: setConnection,
    },
    {
      controlId: "username",
      label: "Username",
      type: "text",
      value: username,
      inputMode: "",
      name: "username",
      condition: wrongPassUser,
      autoComplete: "username",
      function: setUsername,
    },
    {
      controlId: "password",
      label: "Password",
      type: "password",
      value: password,
      inputMode: "",
      name: "password",
      condition: wrongPassUser,
      autoComplete: "current-password",
      function: setPassword,
    },
    {
      controlId: "password",
      label: "Keyphrase",
      type: "password",
      value: adminPassword,
      inputMode: "",
      name: "admin-password",
      condition: wrongAdminpassword,
      autoComplete: "off",
      function: setAdminpassword,
    },
  ];

  const handleLogin = async (e) => {
    e.preventDefault();
    setHasError(false);
    setLoading(true);
    setWrongConnection(false);
    setWrongPassUser(false);

    try {
      const Login = await login({
        url: `https://${connection}.oncloud.gr/s1services`,
        username,
        password,
        language: "GRE",
      });
      if (Login.success) {
        const rights = await checkAdminRights({ key: adminPassword, sn: Login.sn });
        if (rights.success) {
          dispatch(setAdmin({ ...rights, sn: Login.sn }));
          navigate("/admin-panel/login-data");
        } else {
          setHasError(true);
          setWrongAdminPassword(true);
          setErrorMessage(
            "Λανθασμένο Keyphrase</br>Σε περίπτωση απώλειας καλέστε στο <a href='tel:+302165005060'>+302165005060</a> για επαναποστολή"
          );
        }
        setLoading(false);
      } else {
        setHasError(true);
        setErrorMessage(Login.error);
        if (Login.message === "Request failed with status code 404") {
          setWrongConnection(true);
          setErrorMessage(Login.message);
        } else if (Login.error == "Login fails due to invalid login credentials.") {
          setWrongPassUser(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(error);
      setLoading(false);
    }
  };
  return (
    <div className="login-screen">
      {loading && <Spinner />}
      <div className="card">
        <Form onSubmit={handleLogin}>
          {inputs.map((item, index) => {
            return (
              <CrmInput
                label={item.label}
                type={item.type}
                name={item.controlId}
                inputMode={item.inputMode}
                required
                value={item.value}
                key={index}
                autoComplete={item.autoComplete}
                condition={item.condition}
                function={(x) => item.function(x)}
                onChange={(e) => {
                  item.inputMode == "none" && e.target.blur();
                  item.function(e.target.value);
                }}
              />
            );
          })}
          <Button className="w-100 mt-1" type="submit">
            Next
          </Button>
        </Form>
      </div>
      {hasError && (
        <Alert
          variant="danger"
          className="mt-4"
          style={{ width: "450px" }}
          onClose={() => setHasError(false)}
          dismissible
        >
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Alert>
      )}
      <PowererBy />
    </div>
  );
};

export default AdminPanel;
