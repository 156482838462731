import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { getAccesRights, getBrowserInfo, getCommandParams, getCurrentTab } from "../../../../../redux/selectors";
import { TiPlus } from "react-icons/ti";
import getFormDesign from "../../../../../services/getFormDesign";
import {
  newRecord,
  setFormDesign,
  setLoading,
  setObjectState,
  setPrepareNew,
  setScreenState,
} from "../../../../../redux/features/ui/uiSlice";
import preparNew from "../../../../../services/prepareNew";

const New = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const tab = useSelector((state) => getCurrentTab(state, tabID));

  const handleNew = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const FormDesign = tab.formDesign ?? (await getFormDesign(tabID));
    if (FormDesign.success) {
      !tab.formDesign && dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      var locateinfo = "";
      // if (!tab.prepareNew) {
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      // }
      dispatch(newRecord({ tabID }));
    } else {
      console.log(FormDesign);
    }
    dispatch(setScreenState({ value: "form", tabID }));
    dispatch(setObjectState({ value: "new", tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };
  return (
    browserInfo?.browserOnly != "true" &&
    !commandParams.noinsert &&
    accessRights?.create != false && (
      <div
        onClick={handleNew}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <TiPlus size="1.3rem" className="me-1" />
        Νέα
      </div>
    )
  );
};

export default New;
