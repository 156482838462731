import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const session = useSelector((state) => state.session);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    session !== null && navigate(`/${location.search}`);
  }, [session]);

  return session === null && <Outlet />;
};

export default PublicRoute;
