import axios from "axios";

const checkLogin = async (id) => {
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: 1,
      data: {
        service: "checkLogin",
        id,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};
export default checkLogin;
