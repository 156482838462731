import store from "../redux/store";
import { getClientID, getGadget, getGadgetTSortField, getURL } from "../redux/selectors";
import axios from "axios";
import { getDeviceInfo } from "../application/functions/getDeviceInfo";

const analyticGetBrowserInfo = async (id) => {
  const state = store.getState();
  const data = getGadget(state, id)?.data;
  const sort = getGadgetTSortField(state, id) ?? "";
  const url = getURL(state);
  const clientID = getClientID(state);
  const limit = state.settings.app.browserLimit ?? process.env.REACT_APP_GETBROWSERDATA_LIMIT;

  const deviceInfo = await getDeviceInfo();

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getBrowserInfo",
        clientID,
        OBJECT: data?.OBJECT,
        LIST: data?.LIST,
        FORM: data?.FORM,
        FILTERS: data?.FILTERS,
        sort,
        version: 2,
        appID: process.env.REACT_APP_SOFTONE_APPID,
        limit,
        ...deviceInfo,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default analyticGetBrowserInfo;
