import { getMessaging, onMessage } from "firebase/messaging";
import React from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setScreenFromJob } from "../../redux/features/screens/screensSlice";
import { setNewTab } from "../../redux/features/ui/uiSlice";
import { useSelector } from "react-redux";
import { getCurrentTabIndex } from "../../redux/selectors";

const Toast = () => {
  const dispatch = useDispatch();
  const messaging = "serviceWorker" in navigator ? "PushManager" in window && getMessaging() : {};
  const tabID = useSelector((state) => getCurrentTabIndex(state));
  const settings = useSelector((state) => state.settings);

  onMessage(messaging, (payload) => {
    const audio = new Audio("/notification1.wav");
    audio.play();
    const { data } = payload;
    toast.info(<Message title={data.title} body={data.focusBody} />, {
      toastId: payload.messageId,
      onClick: () => {
        toast.dismiss(payload.messageId);
        if (data.link) {
          const { cmd, object, list, form, id, rj, text } = data;
          const command = `${object}&list=${list}${form && `&form=${form}`}&AppId=${
            process.env.REACT_APP_SOFTONE_APPID
          }&w=96&ae=2${rj && `&rj=${rj}`}`;
          const commandParams = { list, form, w: 96, AppId: process.env.REACT_APP_SOFTONE_APPID, autoLocate: true, rj };

          const value = {
            cmd,
            text,
            idx: `url_${id}`,
            autoLocateID: id,
            rj,
            command,
            commandParams,
            screenState: {},
            objectState: "view",
            wasInTab: tabID,
          };
          dispatch(
            setScreenFromJob({
              key: `url_${id}`,
              value,
            })
          );
          dispatch(setNewTab(value));
        }
      },
    });
  });

  const Message = ({ title, body }) => {
    return (
      <div className="d1web-toast">
        <div className="d1web-toast-title">{title}</div>
        <div className="d1web-toast-body">{body}</div>
      </div>
    );
  };

  return (
    <ToastContainer
      toastClassName="shadow"
      position={settings?.app?.desktopNotificationPosition ?? "bottom-right"}
      autoClose={10000}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme={settings.darkMode == 1 ? "dark" : "light"}
      closeButton={true}
    />
  );
};

export default Toast;
