import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import "@fullcalendar/core/locales/el";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux/es";
import {
  getAccesRights,
  getBrowserInfo,
  getCommandByIndex,
  getCommandParams,
  getFormDesign,
  getScreenState,
  getSelectedRow,
  getTabIdx,
  getTableData,
} from "../../../../redux/selectors";
import { calendarObjectsFields } from "../../../../logic/calendarObjectFields";
import setData from "../../../../services/setData";
import serviceGetFormDesign from "../../../../services/getFormDesign";
import {
  setCalendarDates,
  setFormDesign,
  setGetData,
  setLoading,
  setScreenState,
  setSelectedRow,
} from "../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import getData from "../../../../services/getData";

const Calendar = React.memo(({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const menuOpen = useSelector((state) => state.modals.menu);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const tabIDX = useSelector((state) => getTabIdx(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const [calendarApi, setCalendarApi] = React.useState(null);
  const screenState = useSelector((state) => getScreenState(state, tabID));
  const currentTabID = useSelector((state) => state.ui.tabID);

  const doubleClickTimeoutRef = React.useRef(null);

  const [events, setEvents] = React.useState([]);

  const fromDateField = calendarObjectsFields[object].tableData.fromDate;
  const toDateField = calendarObjectsFields[object].tableData.toDate;
  const canZone = settings.calendarColorZoneField != "" && settings.calendarColorZones.length > 0;
  const colorZones = {};
  if (settings.calendarColorZones.length > 0) {
    settings.calendarColorZones.map((item) => {
      colorZones[item.VALUE] = item.COLOR;
    });
  }

  React.useEffect(() => {
    if (browserInfo.success && browserInfo.totalcount > 0) {
      setEvents(
        tableData.map((col) => {
          const hasZoneField =
            canZone && col?.[`${object}_${settings.calendarColorZoneField}`]
              ? col?.[`${object}_${settings.calendarColorZoneField}`]
              : false;
          const colValue = hasZoneField ? hasZoneField.split("|")[0] : undefined;
          const color = hasZoneField ? colorZones?.[colValue] ?? settings.calendarColor : settings.calendarColor;
          return {
            id: col.ZOOMINFO.split(";")[col.ZOOMINFO.split(";").length - 1],
            title: col[calendarObjectsFields[object].tableData.text] ?? "",
            start: col[fromDateField] ? new Date(col[fromDateField]).toISOString() : "",
            end: col[toDateField] ? new Date(col[toDateField]).toISOString() : "",
            color: color,
          };
        })
      );
    }
  }, [tableData]);

  React.useEffect(() => {
    if (currentTabID == tabID && calendarApi) calendarApi.updateSize();
  }, [currentTabID]);

  React.useEffect(() => {
    if (screenState == "browser" && calendarApi) calendarApi.updateSize();
  }, [screenState]);

  React.useEffect(() => {
    // const calendarInstance = calendarRef.current.getApi();
    calendarApi && calendarApi.updateSize();
  }, [menuOpen]);

  const [originalEvent, setOriginalEvent] = React.useState(null);

  const handleEventDragStart = (info) => {
    const { start, end } = info.event;
    setOriginalEvent({ start, end });
  };

  const handleEventDrop = async (info) => {
    const { start, end, id } = info.event;
    dispatch(setLoading({ show: true, tabID }));
    const data = {
      [calendarObjectsFields[object].setDataTable]: [
        {
          [fromDateField.split("_")[1]]: formatDateTime(start),
          [toDateField.split("_")[1]]: formatDateTime(end),
        },
      ],
    };
    const response = await setData({ tabID, data, key: id, defaultObject: object });
    if (response.success) {
      const indexToUpdate = events.findIndex((item) => item.id == id);
      const copyEvents = [...events];
      copyEvents[indexToUpdate] = { ...copyEvents[indexToUpdate], start: start.toISOString(), end: end.toISOString() };
      setEvents(copyEvents);
    } else {
      dispatch(setError({ message: response.error, show: true }));
      info.event.setDates(originalEvent.start, originalEvent.end);
      console.log(response);
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const openRecord = async (id) => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(formDesign ?? {}));
    var noError = true;
    const index = events.findIndex((item) => item.id == id);
    dispatch(setSelectedRow({ value: index, tabID }));
    if (browserInfo.browserOnly != true && commandParams.browseronly != 1) {
      if (accessRights?.browserOnly != true) {
        dispatch(setLoading({ show: true, tabID }));
        if (!FormDesign?.success) {
          const fetchFormDesign = await serviceGetFormDesign(tabID);
          if (fetchFormDesign.success) {
            dispatch(setFormDesign({ value: fetchFormDesign, idx: tabIDX, tabID }));
            dispatch(setScreenState({ value: "form", tabID }));

            FormDesign = fetchFormDesign;
          } else {
            noError = false;
            dispatch(setLoading({ show: false, tabID }));
            console.log(fetchFormDesign);
          }
        } else {
          dispatch(setScreenState({ value: "form", tabID }));
        }

        if (noError) {
          Object.entries(FormDesign.model).forEach(([key, value], index) => {
            locateinfo += index == 0 ? `${key}:` : `;${key}:`;
            if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
            else
              value.fields.map((item) => {
                locateinfo += `${item.name},`;
              });
          });

          const data = await getData({ key: id, locateinfo, tabID });
          if (data.success) {
            dispatch(setGetData({ value: data, tabID }));
            dispatch(setLoading({ show: false, tabID }));
          } else {
            dispatch(setLoading({ show: false, tabID }));
          }
        }
      }
    }
  };

  const handleEventClick = (arg) => {
    // if (doubleClickTimeoutRef.current) {
    //   // Double-click logic here
    openRecord(arg.event.id);
    //   clearTimeout(doubleClickTimeoutRef.current);
    //   doubleClickTimeoutRef.current = null;
    // } else {
    const index = events.findIndex((item) => item.id == arg.event.id);
    dispatch(setSelectedRow({ value: index, tabID }));
    //   // Single-click logic here
    //   doubleClickTimeoutRef.current = setTimeout(() => {
    //     clearTimeout(doubleClickTimeoutRef.current);
    //     doubleClickTimeoutRef.current = null;
    //   }, 300); // Adjust the timeout duration as needed
    // }
  };

  const handleCalendarRef = (ref) => {
    if (ref) {
      setCalendarApi(ref.getApi());
    }
  };

  const buttonText = {
    today: "Σήμερα",
    month: "Μήνας",
    week: "Εβδομάδα",
    day: "Μέρα",
    list: "Λίστα",
    year: "Έτος",
  };

  const headerToolbar = {
    start: "dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    end: "today prev,next",
  };

  const businessHours = {
    daysOfWeek: settings.calendarBussinesDays.split(",").sort(),
    startTime: settings.calendarStart,
    endTime: settings.calendarEnd,
  };

  const views = {
    timeGridWeek: {
      titleFormat: { year: "numeric", month: "long" },
      dayHeaderFormat: { weekday: "short", day: "numeric" },

      //     dayMaxEventRows: 2, // adjust to 6 only for timeGridWeek/timeGridDay
    },
    multiMonthYear: { dayHeaderFormat: { weekday: "short" } },
  };

  const dayHeaderFormat = { weekday: "long" };

  return (
    <div className="browser-calendar w-100 h-100 p-2">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, bootstrap5Plugin, interactionPlugin]}
        initialView="timeGridWeek"
        ref={handleCalendarRef}
        height="100%"
        firstDay={settings.calendarFirstDay}
        locale="el"
        nowIndicator={true}
        eventMaxStack={2}
        dayMaxEventRows={true}
        slotDuration={settings.calendarSlots}
        views={views}
        now={new Date()}
        dayHeaderFormat={dayHeaderFormat}
        headerToolbar={headerToolbar}
        buttonText={buttonText}
        businessHours={businessHours}
        editable={!(accessRights?.browserOnly == true || accessRights?.update == false)}
        selectable={!(accessRights?.browserOnly == true || accessRights?.create == false)}
        allDaySlot={false}
        events={events}
        eventDrop={handleEventDrop}
        eventResize={handleEventDrop}
        eventDragStart={handleEventDragStart}
        eventResizeStart={handleEventDragStart}
        eventColor={settings.calendarColor}
        select={(arg) =>
          dispatch(
            setCalendarDates({ tabID, show: true, start: formatDateTime(arg.start), end: formatDateTime(arg.end) })
          )
        }
        unselectAuto={false}
        scrollTimeReset={false}
        scrollTime={settings.calendarStart ?? "09:00"}
        unselect={() => dispatch(setCalendarDates({ tabID, show: false, start: undefined, end: undefined }))}
        eventClick={handleEventClick}
        eventClassNames={(arg) => (arg.event.id === events?.[selectedRow]?.id ? "shadow calendar-selected-record" : "")}
      />
    </div>
  );
});

export default Calendar;

const formatDateTime = (date) =>
  [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-") +
  " " +
  [date.getHours().padLeft(), date.getMinutes().padLeft(), date.getSeconds().padLeft()].join(":");
