import React from "react";
import { useSelector } from "react-redux";
import {
  getEventCounter,
  getObjectEvent,
  getObjectState,
  getPreviousScreenState,
  getRecData,
} from "../../../redux/selectors";
import store from "../../../redux/store";
import { setAskYesNo, setMessage } from "../../../redux/features/modals/modalsSlice";
import {
  changeToBuffer,
  setChange,
  setLoading,
  setObjectEvent,
  setObjectState,
  setScreenState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";

const S1HtmlCode = ({ element, tabID, screenState }) => {
  const iframeRef = React.useRef(null);
  const objectEvent = useSelector((state) => getObjectEvent(state, tabID));
  const eventCounter = useSelector((state) => getEventCounter(state, tabID));
  const systemParams = useSelector((state) => state.systemParams);

  React.useEffect(() => {
    const iframe = iframeRef.current;

    const handleMessage = async (e) => {
      if (e?.data?.tabID == tabID) {
        switch (e.data.event) {
          case "GET_DATA":
            var state = store.getState();
            var data = getRecData(state, tabID);
            e.source.postMessage({ event: "GET_DATA_RESPONSE", data: data }, e.origin);
            break;
          case "SET_FIELD_VALUE":
            var state = store.getState();
            var objectState = getObjectState(state, tabID);
            if (objectState == "view") {
              await store.dispatch(changeToBuffer({ tabID }));
              store.dispatch(setObjectState({ value: "edit", tabID }));
            }
            var table = e.data.field.split(".")[0];
            var key = e.data.field.split(".")[1];

            store.dispatch(setChange({ tabID, table, key, value: e.data.value }));
            break;
          case "SET_FIELD_VISIBILITY":
            var tab = document.querySelector(`div[data-tab-id="${tabID}"]`);
            if (tab) {
              const form = tab.querySelector(".s1-object-form");
              if (form) {
                const fields = form.querySelectorAll(`div[data-field-name="${e.data.field}"]`);
                fields.forEach((element) => {
                  element.style.visibility = e.data.value ? "visible" : "hidden";
                });
              }
            }
            break;
          case "SET_PANEL_VISIBILITY":
            var tab = document.querySelector(`div[data-tab-id="${tabID}"]`);
            if (tab) {
              const form = tab.querySelector(".s1-object-form");
              if (form) {
                const fields = form.querySelectorAll(`div[data-panel-name="${e.data.panel}"]`);
                fields.forEach((element) => {
                  element.style.visibility = e.data.value ? "visible" : "hidden";
                });
              }
            }
            break;
          case "WARNING":
            store.dispatch(setMessage({ show: true, message: e.data.value }));
            break;
          case "CANCEL_EDITS":
            var state = store.getState();
            var objectState = getObjectState(state, tabID);
            var previousScreenState = getPreviousScreenState(state, tabID);
            if (objectState == "new") {
              store.dispatch(setScreenState({ value: previousScreenState ?? "dialog", tabID }));
            }
            store.dispatch(setObjectEvent({ value: "AFTER_CANCEL", tabID }));
            store.dispatch(setObjectState({ value: "view", tabID }));
            break;
          case "LOADING":
            var state = store.getState();
            store.dispatch(setLoading({ show: e.data.value, tabID }));
            break;
          case "CALCULATE":
            calculate(tabID);
            break;
          case "ASKYESNO":
            var state = store.getState();
            store.dispatch(
              setAskYesNo({
                show: true,
                message: e.data.message,
                callBackYes: () => {
                  e.source.postMessage(
                    {
                      event: "ASKYESNO_RESULT",
                      value: true,
                    },
                    e.origin
                  );
                },
                callBackNo: () => {
                  e.source.postMessage(
                    {
                      event: "ASKYESNO_RESULT",
                      value: false,
                    },
                    e.origin
                  );
                },
              })
            );
            break;
          default:
            break;
        }
      }
    };

    if (iframe) {
      iframe.srcdoc = b64DecodeUnicode(element.code);
      window.addEventListener("message", handleMessage);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [element.code]);

  React.useEffect(() => {
    if (objectEvent) {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.contentWindow.postMessage({ event: objectEvent }, "*");
      }
    }
  }, [eventCounter]);

  const handleiFrameLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow.postMessage({ event: "ON_FRAMELOAD", tabID, systemParams }, "*");
    }
  };

  return <iframe onLoad={handleiFrameLoad} ref={iframeRef} className="s1htmlcode w-100" />;
};

export default S1HtmlCode;

// import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { getObjectState } from "../../../redux/selectors";
// import { changeToBuffer, setChange, setObjectState } from "../../../redux/features/ui/uiSlice";

// const S1HtmlCode = ({ element, tabID }) => {
//   const iframeRef = React.useRef(null);
//   const dispatch = useDispatch()
//   const objectState = useSelector(state=>getObjectState(state,tabID))

//   const setData = (data) => {
//     if (objectState == "view") {
//       dispatch(changeToBuffer({ tabID }));
//       dispatch(setObjectState({ value: "edit", tabID }));
//     }
//     dispatch(setChange({ tabID, table: tableName, key: fieldName, value: row[returnIndex] }));

//   };

//   const openUrl = () => {};

//   useEffect(() => {
//     const interpolatedHtml = atob(element.code).replaceAll("d1Web.tabID", tabID);
//     const iframe = iframeRef.current;

//     iframe.srcdoc = interpolatedHtml;

//     const handleMessage = (event) => {
//       const eventData = event.data;
//       if (eventData?.tabID == tabID) {
//         if (eventData.action === "setData") {
//           setData(eventData.data);
//         }
//       }
//     };

//     if (iframe) {
//       window.addEventListener("message", handleMessage);

//       return () => {
//         window.removeEventListener("message", handleMessage);
//       };
//     }
//   }, []);

//   return <iframe id={`s1-iframe-${tabID}`} ref={iframeRef} className="s1htmlcode w-100" />;
// };

// export default S1HtmlCode;

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}
