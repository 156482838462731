import React from "react";
import { Container } from "react-bootstrap";
import { MdRocketLaunch } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  batchEnd,
  batchLoading,
  batchMessage,
  batchStart,
  setFilterText,
  setLoading,
} from "../../../../redux/features/ui/uiSlice";
import { useSelector } from "react-redux";
import { getCurrentTab, getDialog, getFilters } from "../../../../redux/selectors";
import execBatch from "../../../../services/execBatch";
import batchStatus from "../../../../services/batchStatus";
import { setError } from "../../../../redux/features/modals/modalsSlice";

const TopBarOnDialog = ({ tabID }) => {
  const dispatch = useDispatch();
  const batchStarted = useSelector((state) => getCurrentTab(state, tabID).batchStart);
  const filters = useSelector((state) => getFilters(state, tabID));
  const dialog = useSelector((state) => getDialog(state, tabID));
  const [reqID, setReqID] = React.useState(undefined);

  React.useEffect(() => {
    var intervalId;
    if (reqID) {
      intervalId = setInterval(async () => {
        const status = await batchStatus(reqID);
        console.log(status);
        if (status.status == "done") {
          dispatch(setLoading({ show: false, tabID: tabID }));
          dispatch(batchLoading({ value: true, tabID: tabID }));
          dispatch(batchEnd({ tabID: tabID }));
          dispatch(batchMessage({ tabID: tabID, value: status.data }));
          clearInterval(intervalId);

          // status.data != '""' && dispatch(setError({ message: status.data, show: true }));
        }
      }, 2 * 1000);
    }
    return () => clearInterval(intervalId);
  }, [reqID]);

  const execBatchJob = async () => {
    dispatch(setLoading({ show: true, tabID: tabID }));
    dispatch(batchLoading({ value: true, tabID }));
    dispatch(batchStart({ tabID }));
    var text = "";
    if (filters?.data) {
      for (const [tablename] of Object.entries(filters.data)) {
        for (const [field, value] of Object.entries(filters.data[tablename])) {
          const isMemory = dialog.model[tablename].fields.find((item) => item.name == field).editType == "MemoryTable";
          const isDt = dialog.model[tablename].fields.find((item) => item.name == field).editType == "DateTime";
          text +=
            value != ""
              ? text != ""
                ? `&${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
                : `${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
              : text != ""
              ? isMemory || isDt
                ? `&${tablename}.${field}=NULL`
                : ""
              : isMemory || isDt
              ? `&${tablename}.${field}=NULL`
              : "";
        }
      }
    }
    dispatch(setFilterText({ value: text, tabID }));
    const batch = await execBatch(tabID);
    setReqID(batch.reqID);

    console.log(batch);

    // dispatch(setLoading({ show: false, tabID }));
  };
  return (
    <Container fluid className="object-top-bar">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ cursor: "pointer" }}
          onClick={execBatchJob}
        >
          <MdRocketLaunch className="me-1" />
          <span>Εκτέλεση</span>
        </div>
        {batchStarted && (
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(batchLoading({ value: true, tabID }))}
          >
            <span>Αποτελέσματα</span>
          </div>
        )}
      </div>
    </Container>
  );
};

export default TopBarOnDialog;
