import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGadgetBrowserInfo,
  getGadgetData,
  getGadgetHiddenColumns,
  getGadgetTableColumns,
  getGadgetTableData,
} from "../../../../redux/selectors";
import Head from "../Head";
import Body from "./Body";
import Foot from "./Foot";
import { useFlexLayout, usePagination, useResizeColumns, useTable } from "react-table";
import analyticGetBrowserInfo from "../../../../services/analyticGetBrowserInfo";
import { setBrowserinfo, setLoading } from "../../../../redux/features/portal/portalSlice";
import { getGadgetTimeOut } from "../../../../redux/selectors/portal";

const Category5 = React.memo(({ id, dndProps }) => {
  const dispatch = useDispatch();
  const gagdgetData = useSelector((state) => getGadgetData(state, id));
  const timeOut = useSelector((state) => getGadgetTimeOut(state, id));

  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, id));
  const tableData = useSelector((state) => getGadgetTableData(state, id));
  const tableColumns = useSelector((state) => getGadgetTableColumns(state, id));
  // const flexLayout = useSelector((state) => getFlexLayout(state, tabID));

  const data = React.useMemo(() => tableData ?? [], [tableData]);
  const columns = React.useMemo(() => tableColumns ?? [], [tableColumns]);
  const hiddenColumns = useSelector((state) => getGadgetHiddenColumns(state, id)) ?? [];

  const initialState = {
    pageSize: parseInt(gagdgetData.pageSize ?? 25),
    pageIndex: 0,
    hiddenColumns,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
      initialState,
      // defaultColumn: { columnWidth: !flexLayout ? "auto" : undefined },
    },
    useFlexLayout,
    useResizeColumns,
    usePagination
  );

  React.useEffect(() => {
    let intervalId;
    const initGadget = async () => {
      const browserInfo = await analyticGetBrowserInfo(id);
      dispatch(setBrowserinfo({ value: browserInfo, id }));
      dispatch(setLoading({ id, value: false }));
    };
    setTimeout(() => {
      initGadget();
      if (gagdgetData.refreshRate > 0) {
        intervalId = setInterval(initGadget, 60 * 1000 * gagdgetData.refreshRate);
      }
    }, timeOut);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="gadget d-flex flex-column h-100">
      <Head id={id} dndProps={dndProps} />
      <Body
        id={id}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        rows={rows}
        page={page}
        footerGroups={footerGroups}
      />
      {browserInfo?.totalcount > 0 && (
        <Foot
          id={id}
          footerGroups={footerGroups}
          pageIndex={pageIndex}
          pageSize={pageSize}
          page={page}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
});

export default Category5;
