import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getObjectState, getRecData } from "../../../redux/selectors";

const S1Strings = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const editorData = [];
  element.editor.split("|").forEach((i) => (editorData[i.split("=")[0]] = i.split("=")[1]));

  const [selectedRow, setSelectedRow] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const data = useSelector((state) => getRecData(state, tabID));

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const objectState = useSelector((state) => getObjectState(state, tabID));

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key]) {
        setValue(editorData[data?.[table]?.[0]?.[key]]);
        setSelectedRow(data?.[table]?.[0]?.[key]);
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (currentGridLine?.line?.[key] && grid) {
      setValue(editorData[currentGridLine?.line?.[key]]);
      setSelectedRow(currentGridLine?.line?.[key]);
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (element.value != "") {
        setValue(editorData[element.value]);
        // setValue(element.value);
        setSelectedRow(element.value);
        dispatch(setFilter({ tabID, table, key, value: element.value }));
      }
    }
  }, [screenState]);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const click = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!click) {
          setIsFocused(false);
          setOpenPicker(false);
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    setIsFocused((x) => !x);
  };

  const handleClear = async () => {
    setSelectedRow("");
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        !grid && (await dispatch(changeToBuffer({ tabID })));
        !grid && dispatch(setObjectState({ value: "edit", tabID }));
      }

      dispatch(setChange({ tabID, table, key, value: "" }));
    }
  };

  const handleClickOnRow = async (keyIndex) => {
    setSelectedRow(keyIndex);
    setValue(editorData[keyIndex]);
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") dispatch(setFilter({ tabID, table, key, value: keyIndex }));
    else if (screenState == "form") {
      if (objectState == "view") {
        !grid && (await dispatch(changeToBuffer({ tabID })));
        !grid && dispatch(setObjectState({ value: "edit", tabID }));
      }

      if (grid) {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: keyIndex,
          })
        );
      } else dispatch(setChange({ tabID, table, key, value: keyIndex }));
    }
  };
  return (
    <div
      className="s1strings w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              role={!(element.readOnly || (screenState == "form" && commandParams.readonly)) ? "button" : "none"}
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={true}
              value={value}
              name={element.name}
              onChange={(e) => setValue(e.target.value)}
              onClick={(e) =>
                !(element.readOnly || (screenState == "form" && commandParams.readonly)) && handlePicker(e)
              }
            />
            {value != "" &&
              (clear || openPicker) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={(e) => !(element.readOnly || (screenState == "form" && commandParams.readonly)) && handlePicker(e)}
          >
            <BsChevronDown
              className={`${element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}`}
              color="rgb(64, 180, 252)"
              size="20px"
            />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          ref={pickerRef}
          className="input-picker-div"
          style={{
            left:
              boundRef.current.getBoundingClientRect().left + 400 > window.innerWidth
                ? boundRef.current.getBoundingClientRect().right - 400
                : boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "auto",
          }}
        >
          <table className="input-picker-table w-100">
            <tbody>
              {Object.keys(editorData).map((key) => {
                return (
                  <tr
                    key={key}
                    className={`input-picker-table-row${selectedRow == key ? " input-picker-table-row-selected" : ""}`}
                    onClick={() => handleClickOnRow(key)}
                  >
                    <td className="p-1" style={{ height: "40px" }}>
                      {editorData[key]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default S1Strings;
