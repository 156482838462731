import React from "react";
import { Button, Modal } from "react-bootstrap";
import S1Selector from "../s1 inputs/S1Selector";
import S1NumberField from "../s1 inputs/S1NumberField";
import S1MemoryTable from "../s1 inputs/S1MemoryTable";
import S1TextField from "../s1 inputs/S1TextField";
import S1CalendarDate from "../s1 inputs/S1CalendarDate";
import S1IntField from "../s1 inputs/S1IntField";
import S1Strings from "../s1 inputs/S1Strings";
import { Alert } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getGridByName, getNewData, getObjectState, getTabsOrder } from "../../../redux/selectors";
import {
  addLineToGrid,
  changeToBuffer,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
  updateLineOfGrid,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import S1WebPage from "../s1 inputs/S1WebPage";
import S1CheckBox from "../s1 inputs/S1CheckBox";
const ModalDetailLine = ({ show, setShow, element, tabID }) => {
  const dispatch = useDispatch();

  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const orderTabsLength = useSelector((state) => getTabsOrder(state).length);

  const [error, setError] = React.useState({ show: false, message: "" });
  const grid = useSelector((state) => getGridByName(state, tabID, element.model));
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const render = (element) => {
    switch (element.xtype) {
      case "s1selector":
        return <S1Selector element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1numberfield":
        return <S1NumberField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1intfield":
        return <S1IntField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1strings":
        return <S1Strings element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1memorytable":
        return <S1MemoryTable element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1textfield":
        return <S1TextField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1calendardate":
        return <S1CalendarDate element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1webpage":
        return <S1WebPage element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1checkbox":
        return <S1CheckBox element={element} tabID={tabID} screenState="form" grid={grid} />;
      default:
        return (
          <div style={{ backgroundColor: "red" }} key={element.index}>
            {element.xtype}
          </div>
        );
    }
  };

  React.useEffect(() => setShow(false), [orderTabsLength]);

  const handleAccept = async () => {
    setShow(false);
    var line = { ...grid?.currentLine?.line };
    var gridLines = newData?.[grid.model] ?? [];
    if (objectState == "view") {
      await dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ value: "edit", tabID }));
    }

    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: grid.model }));

    if (line?.LINENUM) {
      //Αν έχει LINENUM τοτε ΔΕΝ είναι καινουργια γραμμή
      //και θα κάνω locate για να την κάνω update
      dispatch(updateLineOfGrid({ tabID, value: line, name: grid.model, gridIndex: grid?.currentLine?.index }));
    } else {
      //Αλλιώς πρέπει να την προσθέσω στον reducer
      dispatch(addLineToGrid({ tabID, value: line, name: grid.model }));
    }
    await calculate(tabID);
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
  };
  return (
    <Modal
      backdropClassName="above-all-backdrop"
      dialogClassName="above-all-dialog"
      backdrop="static"
      animation={false}
      show={show}
      centered
      size="lg"
      onHide={() => setShow(false)}
    >
      <Modal.Header className="p-2 ps-3 bg-light" closeButton style={{ userSelect: "none" }}>
        Γραμμή
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column bg-light"
        style={{ userSelect: "none", maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}
      >
        {grid?.columns &&
          grid.columns.map((item, i) => (
            <div key={i} className="my-1">
              {render(item)}
            </div>
          ))}
      </Modal.Body>
      {error.show && (
        <Alert variant="danger" className="mt-4" onClose={() => setError({ show: false, message: "" })} dismissible>
          {error.message}
        </Alert>
      )}
      {!commandParams.readonly && (
        <Modal.Footer className="p-0 bg-light">
          <Button type="submit" onClick={handleAccept}>
            Επιβεβαίωση
          </Button>
          <Button onClick={() => setShow(false)}>Ακύρωση</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalDetailLine;
