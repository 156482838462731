import axios from "axios";
import store from "../redux/store";
import { getClientID, getCommandByIndex, getSN, getURL } from "../redux/selectors";
import { getDeviceInfo } from "../application/functions/getDeviceInfo";

const getTableFields = async ({ tabID }) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  const command = getCommandByIndex(state, tabID);
  const tablewithDollar = command.split("&")[0];
  const table = tablewithDollar.startsWith("$") ? tablewithDollar.substring(1) : tablewithDollar;
  const object = `${serialnumber}/object=${command}`;
  const deviceInfo = await getDeviceInfo();

  try {
    const data = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getTableFields",
        clientID,
        object,
        table,
        appID: process.env.REACT_APP_SOFTONE_APPID,
        ...deviceInfo,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message };
  }
};

export default getTableFields;
