import { combineReducers } from "redux";

import loginReducer from "./features/login/loginSlice";
import sessionReducer from "./features/session/sessionSlice";
import systemParamsReducer from "./features/systemParams/systemParamsSlice";
import screensReducer from "./features/screens/screensSlice";
import modalsReducer from "./features/modals/modalsSlice";
import uiReducer from "./features/ui/uiSlice";
import settingsReducer from "./features/settings/settingsSlice";
import portalReducer from "./features/portal/portalSlice";
import rootsReducer from "./features/roots/rootsSlice";
import deviceInfoReducer from "./features/deviceInfo/deviceInfoSlice";
import comesFromURLReducer from "./features/comesFromUrl/comesFromUrlSlice";
import adminReducer from "./features/admin/adminSlice";

const rootReducer = combineReducers({
  login: loginReducer,
  session: sessionReducer,
  systemParams: systemParamsReducer,
  screens: screensReducer,
  modals: modalsReducer,
  ui: uiReducer,
  portal: portalReducer,
  settings: settingsReducer,
  roots: rootsReducer,
  deviceInfo: deviceInfoReducer,
  comesFromURL: comesFromURLReducer,
  admin: adminReducer,
});

export default rootReducer;
