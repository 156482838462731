import axios from "axios";
import store from "../redux/store";

const getLoginData = async () => {
  const state = store.getState();
  const clientID = state?.admin?.clientID;
  const sn = state?.admin?.sn;
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: 1,
      data: {
        service: "getLoginData",
        clientID,
        sn,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};
export default getLoginData;
