import React from "react";

const CrmCheckBox = (props) => {
  return (
    <div className="s1checkbox w-100" style={{ flex: `1 1 100%`, overflow: "hidden" }}>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", margin: "0.4rem 0" }}>
        {props.caption != "" && (
          <label
            style={{ flex: 1, height: "30px", borderBottom: "1px dotted #b9b9b9" }}
            className="text-truncate"
            htmlFor={props.name}
          >
            {`${props.caption}:`}
          </label>
        )}
        <input
          type="checkbox"
          className="ms-1"
          name={props.name}
          checked={props.value}
          onChange={props.change}
          style={{ height: "30px", width: "30px", minHeight: "30px", minWidth: "30px" }}
        />
      </div>
    </div>
  );
};

export default CrmCheckBox;
