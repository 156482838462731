import React from "react";
import { AiOutlineUndo } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getObjectState, getPreviousScreenState } from "../../../../../redux/selectors";
import { setObjectEvent, setObjectState, setScreenState } from "../../../../../redux/features/ui/uiSlice";

const CancelEdits = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const previousScreenState = useSelector((state) => getPreviousScreenState(state, tabID));

  const handleCancel = async () => {
    if (objectState == "new") {
      dispatch(setScreenState({ value: previousScreenState ?? "dialog", tabID }));
    }
    await dispatch(setObjectEvent({ value: "AFTER_CANCEL", tabID }));
    dispatch(setObjectState({ value: "view", tabID }));
  };

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => objectState != "view" && handleCancel()}
        style={{
          userSelect: "none",
          cursor: objectState != "view" ? "pointer" : "default",
          opacity: objectState != "view" ? 1 : 0.5,
        }}
      >
        <AiOutlineUndo size="1.2rem" className="me-1" />
        Ακύρωση
      </div>
    </React.Fragment>
  );
};

export default CancelEdits;
