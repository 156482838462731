import React from "react";
import { Container, Navbar, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.svg";
import { BiUser } from "react-icons/bi";
import { BsBuildings } from "react-icons/bs";
import Settings from "./user/Settings";
import LogOut from "./user/LogOut";
import Cookie from "./user/Cookie";
import { setAbout } from "../../redux/features/modals/modalsSlice";
import Password from "./user/Password";

const MainBar = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const systemParams = useSelector((state) => state.systemParams);

  const UserDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      style={{ cursor: "pointer", textDecoration: "none", color: "var(--bs-dark)" }}
      className="d-flex align-items-center justify-cintent-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <div className="ms-1" style={{ fontSize: "0.7rem" }}>
        &#x25bc;
      </div>
    </a>
  ));

  return (
    <div
      id="crm-main-navigator"
      className="crm-main-navigator "
      // style={{ position: "sticky", top: "0", zIndex: 1030 }}
    >
      <Navbar bg="light">
        <Container fluid>
          <Navbar.Brand className="d-flex alin-items-center">
            <img src={logo} width="25" height="25" className="me-2 align-self-center" />
            Web CRM
          </Navbar.Brand>
          <div className="d-flex">
            <a
              style={{ cursor: "pointer", textDecoration: "none", color: "var(--bs-dark)" }}
              className="d-flex align-items-center justify-cintent-center user-select-none me-4"
              onClick={() => dispatch(setAbout(true))}
            >
              <div className="d-flex align-items-center text-truncate" style={{ maxWidth: "250px" }}>
                <BsBuildings size="1.5rem" style={{ minHeight: "1.5rem", minWidth: "1.5rem" }} className="me-1" />
                <div className="d-flex align-items-start justify-content-start flex-column text-truncate">
                  <span className="text-truncate" style={{ fontSize: "0.9rem", maxWidth: "222px" }}>
                    {systemParams?.companyinfo.name}
                  </span>
                  <span className="text-truncate" style={{ fontSize: "0.7rem", maxWidth: "222px" }}>
                    {systemParams?.companyinfo?.branch?.name}
                  </span>
                </div>
              </div>
            </a>

            <Dropdown className="d-flex" align="end">
              <Dropdown.Toggle as={UserDropDown}>
                <div className="d-flex align-items-center me-1 text-truncate" style={{ maxWidth: "250px" }}>
                  <BiUser size="1.5rem" className="me-1" style={{ minHeight: "1.5rem", minWidth: "1.5rem" }} />
                  <span className="text-truncate" style={{ fontSize: "1rem" }}>
                    {login.username}
                  </span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ zIndex: 2001 }}>
                <Password />
                <Cookie />
                <Settings />
                <Dropdown.Divider />
                <LogOut />
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* <Button variant="warning" onClick={() => dipatch(toogleMenu(true))} className="me-2">
            <HiOutlineMenu />
          </Button> */}
        </Container>
      </Navbar>
    </div>
  );
};

export default MainBar;
