import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAskYesNo } from "../../../redux/features/modals/modalsSlice";
import { useSelector } from "react-redux";

const ModalAskYesNo = () => {
  const dispatch = useDispatch();
  const buttonRef = React.useRef(null);
  const { show, message, callBackYes, callBackNo } = useSelector((state) => state.modals.askYesNo);

  React.useEffect(() => {
    if (show) {
      buttonRef.current.focus();
    }
  }, [show]);

  const handleYes = () => {
    dispatch(setAskYesNo({ show: false }));
    callBackYes && callBackYes();
  };

  const handleNo = () => {
    dispatch(setAskYesNo({ show: false }));
    callBackNo && callBackNo();
  };

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      onHide={() => dispatch(setAskYesNo({ show: false }))}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Μήνυμα
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        {message}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button ref={buttonRef} onClick={handleYes}>
          Ναι
        </Button>
        <Button onClick={handleNo}>Όχι</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAskYesNo;
