import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import App from "../App";
import Login from "../application/screens/auth/Login";
import AuthenticateRoute from "./AuthenticateRoute";
import Authenticate from "../application/screens/auth/Authenticate";
import Auth from "../application/screens/auth/Auth";
import { useDispatch } from "react-redux/es";
import { setCoords, setInfo } from "../redux/features/deviceInfo/deviceInfoSlice";
import AdminPanel from "../application/screens/auth/AdminPanel";
import LoginData from "../application/screens/auth/admin panel/LoginData";
import AdminPanelRoute from "./AdminPanelRoute";

const AppRoutes = () => {
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const obj = {};
  //       obj.latitude = position.coords.latitude;
  //       obj.longitude = position.coords.longitude;
  //       dispatch(setCoords(obj));
  //     },
  //     (error) => {
  //       console.error("Error getting geolocation:", error);
  //     }
  //   );
  //   const deviceIfo = {};

  //   deviceIfo.deviceId = navigator.deviceId || "N/A";
  //   deviceIfo.deviceName = navigator.userAgent;
  //   dispatch(setInfo(deviceIfo));
  // }, []);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<App />} />
        </Route>
        <Route exact path="/auth/:type/:provider" element={<PrivateRoute />}>
          <Route exact path="/auth/:type/:provider" element={<Auth />} />
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/admin-panel" element={<PublicRoute />}>
          <Route path="/admin-panel" element={<AdminPanel />} />
        </Route>
        <Route exact path="/admin-panel/login-data" element={<AdminPanelRoute />}>
          <Route exact path="/admin-panel/login-data" element={<LoginData />} />
        </Route>
        <Route path="/authenticate" element={<AuthenticateRoute />}>
          <Route path="/authenticate" element={<Authenticate />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
