import React from "react";
import { Button, Modal } from "react-bootstrap";
import { BiQuestionMark } from "react-icons/bi";

const ConfirmLogOut = ({ show, setShow, handleLogOut }) => {
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      onHide={() => setShow(false)}
      centered
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-color-opposite)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />

      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        Είστε σίγουροι για τον τερματισμό της εφαρμογής;
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button
          onClick={() => {
            setShow(false);
            handleLogOut();
          }}
        >
          Αποσύνδεση
        </Button>
        <Button onClick={() => setShow(false)}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmLogOut;
