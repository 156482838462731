import React from "react";
import { GrOnedrive } from "react-icons/gr";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import { useDispatch } from "react-redux";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
const OneDriveJS = ({ setMenu, handleChange }) => {
  const dispatch = useDispatch();
  return (
    <ReactOneDriveFilePicker
      clientID={process.env.REACT_APP_SHAREPOINT_CLIENT_ID}
      action="share"
      multiSelect={false}
      advanced={{
        redirectUri: process.env.REACT_APP_SHAREPOINT_REDIRECT_URL,
      }}
      onSuccess={(result) => {
        handleChange(result.value[0].webUrl);
      }}
      onError={(er) => {
        dispatch(setError({ show: true, message: er.message }));
      }}
    >
      <div
        className="tab-list-context-menu-action"
        role="button"
        onClick={() => {
          setMenu({ show: false });
        }}
      >
        <GrOnedrive className="me-1" />
        <span>OneDrive</span>
      </div>
    </ReactOneDriveFilePicker>
  );
};

export default OneDriveJS;
