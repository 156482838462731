import axios from "axios";
import { getDeviceInfo } from "../application/functions/getDeviceInfo";
import { getTimeZoneOffset } from "../application/functions/getTimeZoneOffset";

const login = async ({ url, username, password, language }) => {
  const deviceInfo = await getDeviceInfo();

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "login",
        username,
        password,
        language,
        timezoneoffset: getTimeZoneOffset(),
        appID: process.env.REACT_APP_SOFTONE_APPID,
        ...deviceInfo,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default login;
