export const getDeviceInfo = () => {
  return new Promise((resolve, reject) => {
    const deviceInfo = {
      latitude: "",
      longitude: "",
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          deviceInfo.latitude = position.coords.latitude;
          deviceInfo.longitude = position.coords.longitude;
          resolve(deviceInfo);
        },
        () => {
          resolve(deviceInfo);
        }
      );
    } else {
      resolve(deviceInfo);
    }
  });
};
