import React from "react";
import { Container } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { TiPlus } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  newRecord,
  setBrowserInfo,
  setFilterText,
  setFormDesign,
  setLoading,
  setObjectState,
  setPrepareNew,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import { getAccesRights, getCommandParams, getCurrentTab, getDialog, getFilters } from "../../../../redux/selectors";
import getFormDesign from "../../../../services/getFormDesign";
import preparNew from "../../../../services/prepareNew";
import getBrowserInfo from "../../../../services/getBrowserInfo";
const TopBarOnDialog = ({ tabID }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const dialog = useSelector((state) => getDialog(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const handleNew = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const FormDesign = tab.formDesign ?? (await getFormDesign(tabID));
    if (FormDesign.success) {
      !tab.formDesign && dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      var locateinfo = "";
      // if (!tab.prepareNew) {
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      // }
      dispatch(newRecord({ tabID }));
    } else {
      console.log(FormDesign);
    }
    dispatch(setScreenState({ value: "form", tabID }));

    dispatch(setObjectState({ value: "new", tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleGoToBrowser = async () => {
    dispatch(setLoading({ show: true, tabID }));
    var text = "";
    if (filters?.data) {
      for (const [tablename] of Object.entries(filters.data)) {
        for (const [field, value] of Object.entries(filters.data[tablename])) {
          const isMemory = dialog.model[tablename].fields.find((item) => item.name == field).editType == "MemoryTable";
          const isDt = dialog.model[tablename].fields.find((item) => item.name == field).editType == "DateTime";
          text +=
            value != ""
              ? text != ""
                ? `&${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
                : `${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
              : text != ""
              ? isMemory || isDt
                ? `&${tablename}.${field}=NULL`
                : ""
              : isMemory || isDt
              ? `&${tablename}.${field}=NULL`
              : "";
        }
      }
    }
    dispatch(setFilterText({ value: text, tabID }));
    const browserInfo = await getBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
    dispatch(setScreenState({ value: "browser", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <div
        onClick={handleGoToBrowser}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <HiSearch size="1.1rem" className="me-1" />
        Λίστα
      </div>
      <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer" }}>
        <FaFilter size="0.9rem" />
      </div>
      {dialog?.editable && accessRights?.create != false && (
        <div
          onClick={handleNew}
          className="d-flex align-items-center justify-content-start"
          style={{ cursor: "pointer" }}
        >
          <TiPlus size="1.3rem" className="me-1" />
          <span>Νέα</span>
        </div>
      )}
    </Container>
  );
};

export default TopBarOnDialog;
