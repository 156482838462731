import { setError } from "../../redux/features/modals/modalsSlice";
import { setBrowserInfo, setLoading, setObjectEvent, setScreenState } from "../../redux/features/ui/uiSlice";
import {
  getData,
  getFormDesign,
  getObjectState,
  getScreenState,
  getSelectedRow,
  getTableData,
} from "../../redux/selectors";
import store from "../../redux/store";
import delData from "../../services/delData";
import getBrowserInfo from "../../services/getBrowserInfo";

const deleteRecord = async (tabID) => {
  const state = store.getState();
  const dispatch = store.dispatch;
  dispatch(setLoading({ show: true, tabID }));
  const screenState = getScreenState(state, tabID);
  const objectState = getObjectState(state, tabID);
  let id2Delete;

  if (screenState == "browser") {
    const selectedRow = getSelectedRow(state, tabID);
    const tableData = getTableData(state, tabID);
    id2Delete = tableData[selectedRow].ZOOMINFO.substring(tableData[selectedRow].ZOOMINFO.indexOf(";") + 1);
  } else if (screenState == "form") {
    const data = getData(state, tabID);
    const key = getFormDesign(state, tabID).key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    id2Delete = data?.[tableName]?.[0]?.[fieldName] ?? "";
  }
  const response = await delData(id2Delete, tabID);
  if (response.success) {
    await dispatch(setObjectEvent({ tabID, value: "AFTER_DELETE" }));
    const browserInfo = await getBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    if (screenState == "form") {
      dispatch(setScreenState({ value: "browser", tabID }));
    }
  } else {
    console.log(response);
    dispatch(setError({ show: true, message: response.error }));
  }
  dispatch(setLoading({ show: false, tabID }));
};

export default deleteRecord;
