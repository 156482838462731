import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { changeToBuffer, setChange, setFilter, setObjectState } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";

const S1SubForm = ({ element, tabID, screenState, currentLine }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const tableIndex = formDesign?.model?.[table]?.relationship == "OneToOne" ? 0 : currentLine?.index ?? 0;

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");
  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const handleCalculate = async (table, key, value) => {
    dispatch(setChange({ tabID, table, tableIndex, key, value }));
    model?.updates && (await calculate(tabID, objectState));
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form") {
      setValue(data?.[table]?.[0]?.[key] ?? "");
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handleChange = async (value) => {
    setIsFocused(false);
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if ((data?.[table]?.[0]?.[key] ?? "") != value) {
        if (objectState == "view") {
          await dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ value: "edit", tabID }));
        }
        handleCalculate(table, key, value);
      }
    }
  };

  const handleClear = async () => {
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);

    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate(table, key, "");
    }
  };

  return (
    <div
      className="s1subform w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={element.index}>
          {`${element.caption}:`}
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && commandParams.readonly)}
              value={value}
              autoComplete="off"
              name={element.name}
              onChange={(e) => {
                setValue(e.target.value);
                setOpenPicker(false);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={(e) => handleChange(e.target.value)}
            />
            {value != "" &&
              (clear || isFocused) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default S1SubForm;
