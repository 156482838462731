import React from "react";
import { SiGoogledrive } from "react-icons/si";
import useDrivePicker from "react-google-drive-picker";

const GoogleDrive = ({ setMenu, handleChange }) => {
  const [openPicker] = useDrivePicker({
    authImmediate: true,
  });

  const handleAddNewDocs = (data) => {
    if (data.action === "picked") {
      const { url } = data.docs[0];
      handleChange(url);
    } else {
      console.log(data.action);
    }
  };

  const handleOpenPicker = (e) => {
    e.stopPropagation();
    setMenu({ show: false });
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      customScopes: ["https://www.googleapis.com/auth/drive"],
      callbackFunction: (data) => handleAddNewDocs(data),
    });
  };

  return (
    <div className="tab-list-context-menu-action" role="button" onClick={handleOpenPicker}>
      <SiGoogledrive className="me-1" />
      <span>Google Drive</span>
    </div>
  );
};

export default GoogleDrive;
