import React from "react";
import loadGadgetsParams from "../../../services/loadGadgetParams";
import { useDispatch, useSelector } from "react-redux";
import { gadgetParams, loadGadgets, setPortalRef, updateGadgetOrder } from "../../../redux/features/portal/portalSlice";
import ServiceloadGadgets from "../../../services/loadGadgets";
import ScreenSpinner from "../ScreenSpinner";
import { getGadgets, getGadgetsParams } from "../../../redux/selectors";
import Gadget from "./Gadget";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TopBarOnDashBoard from "../../bars/top bars/dashboard/TopBarOnDashBoard";

const DashBoard = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const params = useSelector((state) => getGadgetsParams(state));
  const gadgets = useSelector((state) => getGadgets(state));
  const gadgetOrder = useSelector((state) => state.portal.gadgetsOrder);
  const fullWindow = useSelector((state) => state.portal.fullWindow);
  const scroll = useSelector((state) => state.portal.scroll);

  const enablePortal = useSelector((state) => state.settings?.app?.enablePortal);

  const barRef = React.useRef(null);
  const portalRef = React.useRef(null);
  const initGadgetParams = async () => {
    setLoading(true);
    const params = await loadGadgetsParams();
    dispatch(gadgetParams(params));
    if (params.success && Object.keys(params.data).length > 0) {
      var codes = Object.keys(params.data.portlets)
        .map((x) => x.substring(2, x.length))
        .join(",");
      if (codes != "") {
        const LoadGadgets = await ServiceloadGadgets({ codes });
        LoadGadgets.success && dispatch(loadGadgets(LoadGadgets.gadgets));
      }
    }
    setLoading(false);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    dispatch(updateGadgetOrder({ source: source.index, destination: destination.index }));
  };

  React.useEffect(() => {
    if (fullWindow == undefined && scroll) {
      portalRef.current.scrollTo(0, scroll);
    }
  }, [fullWindow]);

  React.useEffect(() => {
    enablePortal == 1 && initGadgetParams();
  }, []);
  return (
    <>
      {gadgets != null && <TopBarOnDashBoard />}
      <div className="s1-portal" ref={portalRef} id="d1-crm-portal">
        {loading ? (
          <ScreenSpinner />
        ) : params?.data == {} ? (
          <>No gadgets selected</>
        ) : (
          gadgets != null && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} className={fullWindow ? "h-100" : ""} ref={provided.innerRef}>
                    {gadgetOrder.map(
                      (key, index) =>
                        gadgets[key].show && (
                          <Draggable key={key} draggableId={key} index={index} isDragDisabled={key == fullWindow}>
                            {(provided) => <Gadget key={key} id={key} provided={provided} />}
                          </Draggable>
                        )
                      // gadgets[key].category != 2 && <Gadget key={key} id={key} dndProps={provided.dragHandleProps} />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        )}
      </div>
    </>
  );
});

export default DashBoard;
