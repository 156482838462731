import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(clear, () => {
  //       localStorage.setItem("login", null);
  //       return null;
  //     });
  //   },
});

export const { setAdmin } = adminSlice.actions;

export default adminSlice.reducer;
