import axios from "axios";

const checkAdminRights = async ({ sn, key }) => {
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: 1,
      data: {
        service: "checkAdminRights",
        sn,
        key,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};
export default checkAdminRights;
