import React from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { BsDatabaseLock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const CrmInput = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const navigate = useNavigate();
  const [showPass, setShowPass] = React.useState(false);

  return (
    <div className="s1input w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <div className={`inputGroup${props.condition ? " has-error" : ""}`}>
        <label className="text-truncate" htmlFor={props.index}>
          {props.label}:
        </label>
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
        >
          <div className="d-flex w-100">
            <input
              className="w-100"
              name={props.name}
              style={{ backgroundColor: "white" }}
              autoComplete={props.autoComplete}
              disabled={props.readOnly}
              readOnly={props.readOnly}
              type={showPass ? "text" : props.type}
              value={props.value}
              required={props.required}
              onChange={(e) => props.function(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            {props.type == "password" && props.value != "" && (
              <div
                onClick={() => {
                  setShowPass((x) => !x);
                }}
              >
                {showPass ? (
                  <AiOutlineEyeInvisible size="30px" role="button" />
                ) : (
                  <AiOutlineEye size="30px" role="button" />
                )}
              </div>
            )}
            {props.name == "connection" && (
              <div onClick={() => navigate("/admin-panel")}>
                <BsDatabaseLock size="25px" role="button" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmInput;
