import React from "react";
import PowererBy from "../../../../assets/PowererBy";
import getLoginData from "../../../../services/getLoginData";
import Spinner from "../../../ui/Spinner";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TableLogin from "./TableLogin";

const LoginData = () => {
  const [loading, setLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const tableColumns = React.useMemo(() => [
    {
      Header: "CCCLOGINLOG",
      hidden: true,
      accessor: "CCCLOGINLOG",
    },
    {
      Header: "SN",
      accessor: "SN",
    },
    {
      Header: "USERNAME",
      accessor: "USERNAME",
    },
    {
      Header: "COMPANYREF",
      accessor: "COMPANYREF",
    },
    {
      Header: "BRANCHREF",
      accessor: "BRANCHREF",
    },
    {
      Header: "USERREF",
      accessor: "USERREF",
    },
    {
      Header: "LOGIN",
      accessor: "LOGIN",
    },
  ]);
  const [tableData, setTableData] = React.useState([]);
  const navigate = useNavigate();

  const fetchLoginData = async () => {
    const response = await getLoginData();
    console.log(response);
    if (response.success && response?.count > 0) {
      setTableData(response.data);
    }
    setLoading(false);
    setShow(true);
  };

  const data = tableData;
  const columns = tableColumns;

  const initialState = {
    pageIndex: 0,
    pageSize: 35,
    // hiddenColumns: ["CCCLOGINLOG"],
  };

  //   const {
  //     getTableProps,
  //     getTableBodyProps,
  //     headerGroups,
  //     footerGroups,
  //     rows,
  //     prepareRow,
  //     state: { pageIndex },
  //     page,
  //     canPreviousPage,
  //     canNextPage,
  //     pageOptions,
  //     pageCount,
  //     gotoPage,
  //     nextPage,
  //     previousPage,
  //     resetResizing,
  //   } = useTable(
  //     {
  //       columns,
  //       data,
  //       initialState,
  //     },
  //     useBlockLayout,
  //     useResizeColumns,
  //     usePagination
  //   );

  React.useEffect(() => {
    fetchLoginData();
  }, []);
  return (
    <div className="login-screen">
      {loading && <Spinner />}
      <Modal
        show={show}
        onHide={() => navigate("/admin-panel")}
        fullscreen
        animation={false}
        className="d-flex align-items-end"
        dialogClassName="p-3"
        contentClassName="rounded overflow-hidden shadow"
      >
        <TableLogin columns={columns} data={tableData} />
      </Modal>
      <PowererBy />
    </div>
  );
};

export default LoginData;
