import React, { StrictMode } from "react";
import Dropbox from "./Dropbox";
import GoogleDrive from "./GoogleDrive";
import OneDriveJS from "./OneDrive";

const WebPageContextMenu = ({ menu, setMenu, handleChange }) => {
  const menuRef = React.useRef(null);

  const [menuWidth, setMenuWidth] = React.useState(0);

  React.useEffect(() => {
    if (menu.show) {
      setMenuWidth(menuRef?.current?.offsetWidth);
      const clickListener = (e) => {
        if (!menuRef.current.contains(e.target)) {
          setMenu({ show: false });
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [menu]);

  return (
    menu.show && (
      <div
        ref={menuRef}
        className="tab-list-context-menu select-none"
        style={{
          top: menu.y,
          left: menu.x + menuWidth > window.innerWidth ? window.innerWidth - menuWidth : menu.x,
        }}
      >
        <Dropbox setMenu={setMenu} handleChange={handleChange} />
        <GoogleDrive setMenu={setMenu} handleChange={handleChange} />
        <StrictMode>
          <OneDriveJS setMenu={setMenu} handleChange={handleChange} />
        </StrictMode>
      </div>
    )
  );
};

export default WebPageContextMenu;
