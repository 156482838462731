import React from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CrmInput from "../../ui/CrmInput";
import login from "../../../services/login";
import { useDispatch } from "react-redux";
import { set as setLogin } from "../../../redux/features/login/loginSlice";
import Spinner from "../../ui/Spinner";
import PowererBy from "../../../assets/PowererBy";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [erp, setErp] = React.useState("Softone");
  const [connection, setConnection] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [openERP, setOpenERP] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [wrongConnection, setWrongConnection] = React.useState(false);
  const [wrongPassUser, setWrongPassUser] = React.useState(false);
  const inputs = [
    {
      controlId: "connection",
      label: "Server",
      type: "text",
      value: connection,
      inputMode: "",
      condition: wrongConnection,
      autoComplete: "off",
      function: setConnection,
    },
    {
      controlId: "username",
      label: "Username",
      type: "text",
      value: username,
      inputMode: "",
      name: "username",
      condition: wrongPassUser,
      autoComplete: "username",
      function: setUsername,
    },
    {
      controlId: "password",
      label: "Password",
      type: "password",
      value: password,
      inputMode: "",
      name: "password",
      condition: wrongPassUser,
      autoComplete: "current-password",
      function: setPassword,
    },
  ];

  const handleLogin = async (e) => {
    e.preventDefault();
    setHasError(false);
    setLoading(true);
    setWrongConnection(false);
    setWrongPassUser(false);

    try {
      const Login = await login({
        url: `https://${connection}.oncloud.gr/s1services`,
        username,
        password,
        language: "GRE",
      });
      if (Login.success) {
        dispatch(
          setLogin({
            username,
            password,
            sn: Login.sn,
            objs: Login.objs,
            clientID: Login.clientID,
          })
        );
        localStorage.setItem("lastLogin", JSON.stringify({ connection, username, password }));
        setLoading(false);
        navigate(`/authenticate${location.search}`);
      } else {
        setHasError(true);
        setErrorMessage(Login.error);
        if (Login.message === "Request failed with status code 404") {
          setWrongConnection(true);
          setErrorMessage(Login.message);
        } else if (Login.error == "Login fails due to invalid login credentials.") {
          setWrongPassUser(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const previous = JSON.parse(localStorage.getItem("lastLogin"));
    setConnection(previous?.connection ?? "");
    setUsername(previous?.username ?? "");
    setPassword(previous?.password ?? "");
  }, []);

  return (
    <div className="login-screen">
      {loading && <Spinner />}
      <div className="card">
        <Form onSubmit={handleLogin}>
          <CrmInput
            type="text"
            label="ERP"
            name="ERP"
            readOnly
            required
            inputMode="none"
            value={erp}
            onChange={(e) => setErp(e.target.value)}
            onClick={(e) => {
              e.target.blur();
              setOpenERP(true);
            }}
          />
          {inputs.map((item, index) => {
            return (
              <CrmInput
                label={item.label}
                type={item.type}
                name={item.controlId}
                inputMode={item.inputMode}
                required
                value={item.value}
                key={index}
                autoComplete={item.autoComplete}
                condition={item.condition}
                function={(x) => item.function(x)}
                onChange={(e) => {
                  item.inputMode == "none" && e.target.blur();
                  item.function(e.target.value);
                }}
              />
            );
          })}
          <Button disabled={loading} className="w-100 mt-1" type="submit">
            Next
          </Button>
        </Form>
      </div>
      {hasError && (
        <Alert
          variant="danger"
          className="mt-4"
          style={{ width: "450px" }}
          onClose={() => setHasError(false)}
          dismissible
        >
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Alert>
      )}
      <PowererBy />
    </div>
  );
};

export default Login;
