import load from "load-script";
import React from "react";
import { SiDropbox } from "react-icons/si";

const Dropbox = ({ setMenu, handleChange }) => {
  const dropboxOptions = {
    success: (files) => {
      const { link } = files[0];
      handleChange(link);
    },
    cancel: () => console.log("cancel"),
    linkType: "preview", // or "direct"
    multiselect: false, // or true
    folderselect: true,
  };

  const handleDropBox = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (window.Dropbox) {
        setMenu({ show: false });
        window.Dropbox.choose(dropboxOptions);
      }
    },
    [dropboxOptions]
  );

  React.useEffect(() => {
    load(process.env.REACT_APP_DROPBOX_SDK_URL, {
      attrs: {
        id: process.env.REACT_APP_DROPBOX_ID,
        "data-app-key": process.env.REACT_APP_DROPBOX_CLIENT_ID,
      },
    });
  }, []);
  return (
    <div
      className="tab-list-context-menu-action"
      role="button"
      onClick={(e) => {
        setMenu({ show: false });
        handleDropBox(e);
      }}
    >
      <SiDropbox className="me-1" />
      <span>DropBox</span>
    </div>
  );
};

export default Dropbox;
