import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { set } from "../../../redux/features/settings/settingsSlice";

const CrmInteger = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState(settings[props.name]);

  const handleInput = (e) => {
    const { value } = e.target;
    setValue(value.replace(/\D/g, ""));
  };

  React.useEffect(() => {
    setValue(settings[props.name]);
  }, []);

  const handleChange = (value) => {
    setIsFocused(false);
    if ((value != settings[props.name] && value > 0) || settings[props.name]) {
      dispatch(set({ key: props.name, value: parseInt(value) }));
    } else if (value == "" || value < 1) {
      setValue(settings[props.name]);
    }
  };

  return (
    <div
      className="s1intfield w-100"
      style={{
        flex: `1 1 100%`,
        overflow: "hidden",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", margin: "0.4rem 0" }}>
        {props.caption != "" && (
          <label
            style={{ flex: 1, height: "30px", borderBottom: "1px dotted #b9b9b9" }}
            className="text-truncate"
            htmlFor={props.name}
          >
            {`${props.caption}:`}
          </label>
        )}
        <div
          className={`${isFocused ? "underline-focus" : ""}`}
          style={{
            widht: "80px",
            display: "flex",
            marginLeft: "0.25rem",
            borderBottom: "1px solid #b9b9b9",
            height: "30px",
          }}
        >
          <input
            autoComplete="off"
            style={{ backgroundColor: "transparent", textAlign: "end", border: "none", outline: "none" }}
            name={props.name}
            value={value}
            onChange={handleInput}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default CrmInteger;
