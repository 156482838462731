import React from "react";
import { Container } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterText,
  setLoading,
  setReportData,
  setReportInfo,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import { getDialog, getFilters } from "../../../../redux/selectors";
import getReportInfo from "../../../../services/getReportInfo";
import getReportData from "../../../../services/getReportData";
const TopBarOnDialog = ({ tabID }) => {
  const filters = useSelector((state) => getFilters(state, tabID));
  const dispatch = useDispatch();
  const dialog = useSelector((state) => getDialog(state, tabID));

  const handleGoToBrowser = async () => {
    dispatch(setLoading({ show: true, tabID }));
    var text = "";
    if (filters?.data) {
      for (const [tablename] of Object.entries(filters.data)) {
        for (const [field, value] of Object.entries(filters.data[tablename])) {
          const isMemory = dialog.model[tablename].fields.find((item) => item.name == field).editType == "MemoryTable";
          const isDt = dialog.model[tablename].fields.find((item) => item.name == field).editType == "DateTime";
          text +=
            value != ""
              ? text != ""
                ? `&${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
                : `${tablename}.${field}=${isMemory ? value.split("|")[0] : value}`
              : text != ""
              ? isMemory || isDt
                ? `&${tablename}.${field}=NULL`
                : ""
              : isMemory || isDt
              ? `&${tablename}.${field}=NULL`
              : "";
        }
      }
    }
    dispatch(setFilterText({ value: text, tabID }));
    const reportInfo = await getReportInfo(tabID);
    dispatch(setReportInfo({ value: reportInfo, tabID }));

    if (reportInfo.success) {
      if (reportInfo.npages > 0) {
        const reportData = await getReportData({
          reqID: reportInfo.reqID,
          pagenum: 1,
        });
        if (reportData.success) {
          reportData.pageNum = 1;
          dispatch(setReportData({ value: reportData, tabID }));
        } else {
          console.log(reportData);
        }
      } else {
        console.log("no data");
      }
    } else {
      console.log(reportInfo);
    }
    dispatch(setLoading({ show: false, tabID }));
    dispatch(setScreenState({ value: "browser", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <div
        onClick={handleGoToBrowser}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <HiSearch size="1.1rem" className="me-1" />
        Λίστα
      </div>
      <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer" }}>
        <FaFilter size="0.9rem" />
      </div>
    </Container>
  );
};

export default TopBarOnDialog;
