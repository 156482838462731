import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGridLine, getNewData, getObjectState } from "../../../redux/selectors";
import {
  changeToBuffer,
  deleteLineFromGrid,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import { BiQuestionMark } from "react-icons/bi";

const DeleteLineModal = ({ show, setShow, tabID, element }) => {
  const dispatch = useDispatch();
  const grid = useSelector((state) => getCurrentGridLine(state, tabID, element.model));
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const handleAccept = async () => {
    setShow(false);
    if (objectState == "view") {
      await dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ value: "edit", tabID }));
    }
    var gridLines = newData?.[element.model] ?? [];
    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
    dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex: grid.index }));
    await calculate(tabID);
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
  };
  return (
    <Modal backdrop="static" animation={false} show={show} centered size="sm" onHide={() => setShow(false)}>
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-color-opposite)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
      <Modal.Body className="d-flex flex-column" style={{ userSelect: "none" }}>
        Επιβεβαίωση διαγραφής;
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button type="submit" onClick={handleAccept}>
          Επιβεβαίωση
        </Button>
        <Button onClick={() => setShow(false)}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteLineModal;
