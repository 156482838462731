import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccesRights, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";

const S1CalendarDate = ({ element, screenState, tabID, grid }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const currentGridLine = grid?.currentLine;

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  const handleClear = async () => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      if (objectState == "view" && !grid) {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate("");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] && data?.[table]?.[0]?.[key] != "") {
        setValue(dateConverter(data?.[table]?.[0]?.[key]));
        setViewValue(displayConverter(data?.[table]?.[0]?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (screenState == "form" && grid) {
      if (currentGridLine?.line?.[key] && currentGridLine?.line?.[key] != "") {
        setValue(dateConverter(currentGridLine?.line?.[key]));
        setViewValue(displayConverter(currentGridLine?.line?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (filters?.data?.[table]?.[key] && filters?.data?.[table]?.[key] != "") {
        setValue(dateConverter(filters?.data?.[table]?.[key]));
        setViewValue(displayConverter(filters?.data?.[table]?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [filters?.data?.[table]?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
    } else if (screenState == "form") {
      handleCalculate(isDateTime ? dateTimeConverter(value) : dateConverter(value));
    }
  };

  // React.useEffect(() => {
  //   if (isFocused) {
  //     // dateRef.current.showPicker();
  //     dateRef.current.click();
  //     dateRef.current.focus();
  //   }
  // }, [isFocused]);

  const showPicker = () => {
    dateRef.current.showPicker();
    dateRef.current.click();
    dateRef.current.focus();
  };

  const handleInputChange = (value) => {
    let newValue = value;
    // Check if the new value has a length of 2 and doesn't end with a slash
    if (newValue.length === 2 && !newValue.endsWith("/")) {
      newValue += "/";
    }

    setViewValue(newValue);
    // const [date, month, year] = formattedDate.split("/");

    // if (formattedDate.split("/").length == 1) {
    //   //Έχει περαστεί μόνο ημερομηνία
    //   if (date.length == 1) {
    //     if (date >= 4) {
    //       formattedDate = `${date}/`;
    //     }
    //   }
    // } else if (formattedDate.split("/").length == 2) {
    // } else if (formattedDate.split("/").length == 3) {
    // }

    // setViewValue(formattedDate);
  };

  const handleBlur = () => {
    // setIsFocused(false);
    // const today = new Date();
    // const currentMonth = String(today.getMonth() + 1).padStart(2, "0");
    // const currentYear = today.getFullYear();
    // let formattedDate = viewValue;
    // const array = formattedDate.split("/")
    // if (array.length==1){//Έχει περαστεί μόνο ημερομηνία
    //   if (array[0].length === 1) {
    //     if (array[0] >= 4 && array[0] < 10){
    //       formattedDate = `0${formattedDate}/`
    //     }else if (){
    //     }
    //   }
    // }
    // // If only one part (day or month) is entered, append current month and year
    // if (formattedDate.length <= 2) {
    //   formattedDate = `${formattedDate}/${currentMonth}/${currentYear}`;
    // }
    // // If two parts (day and month) are entered, append current year
    // if (formattedDate.split("/").length === 2) {
    //   formattedDate = `${formattedDate}/${currentYear}`;
    // }
    // setViewValue(formattedDate);
  };

  return (
    <div
      className="s1calendardate w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              ref={dateRef}
              type="text"
              className={`w-100${value != "" ? " date-input-has-value" : ""}${
                element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                  ? " disabled"
                  : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              readOnly={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              value={viewValue}
              name={element.name}
              onChange={(e) => !element.readOnly && handleInputChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => handleBlur(false)}
              // onClick={() => {
              //   !element.readOnly && dateRef.current.showPicker();
              //   !element.readOnly && dateRef.current.click();
              //   !element.readOnly && dateRef.current.focus();
              // }}
            />
            {value != "" && clear && !element.readOnly && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)) && (
            <div
              style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            >
              <BsCalendar3
                className={
                  element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                    ? "disabled"
                    : ""
                }
                color="rgb(64, 180, 252)"
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
      <input
        type="date"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1CalendarDate;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};

const displayConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
  } else {
    return value;
  }
};
