import React from "react";
import { GrOnedrive } from "react-icons/gr";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import { useDispatch } from "react-redux";
import { getNewData, getObjectState } from "../../../../redux/selectors";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import { useSelector } from "react-redux";
import {
  addMultipleLinesToGrid,
  changeToBuffer,
  setObjectState,
  triggerGridChange,
} from "../../../../redux/features/ui/uiSlice";

const OneDrive = ({ tabID, element, setMenu }) => {
  const dispatch = useDispatch();
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const handleAddNewDocs = async (data) => {
    setMenu({ show: false });
    const lines = [];
    for (const docs of data.value) {
      const { webUrl, name } = docs;
      lines.push({
        SOFNAME: webUrl,
        NAME: name,
      });
    }
    if (objectState == "view") {
      await dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ value: "edit", tabID }));
    }
    const gridLines = newData?.[element.model] ?? [];
    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
    dispatch(addMultipleLinesToGrid({ tabID, value: lines, name: element.model }));
  };

  return (
    <ReactOneDriveFilePicker
      clientID={process.env.REACT_APP_SHAREPOINT_CLIENT_ID}
      action="share"
      multiSelect={true}
      advanced={{
        redirectUri: process.env.REACT_APP_SHAREPOINT_REDIRECT_URL,
      }}
      onSuccess={(data) => handleAddNewDocs(data)}
      onError={(er) => {
        dispatch(setError({ show: true, message: er.message }));
      }}
    >
      <div
        className="tab-list-context-menu-action"
        role="button"
        onClick={() => {
          setMenu({ show: false });
        }}
      >
        <GrOnedrive className="me-1" />
        <span>OneDrive</span>
      </div>
    </ReactOneDriveFilePicker>
  );
};

export default OneDrive;
