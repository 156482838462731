import axios from "axios";
import store from "../redux/store";
import { getClientID, getCommandByIndex, getFilters, getSN, getURL } from "../redux/selectors";
import { getDeviceInfo } from "../application/functions/getDeviceInfo";

const getReportInfo = async (tabID) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  const command = getCommandByIndex(state, tabID);
  const filters = getFilters(state, tabID)?.text != "" ? getFilters(state, tabID)?.text : undefined;
  const object = `${serialnumber}/object=${command}`;
  const deviceInfo = await getDeviceInfo();

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getReportInfo",
        clientID,
        object,
        filters,
        appID: process.env.REACT_APP_SOFTONE_APPID,
        ...deviceInfo,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getReportInfo;
